import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

const Default = ({ enabled, isEditable, valueDefault, updateOutput, questionId }) => {
	if (!enabled) {
		return null;
	}

	return (
		<div className="p-col-12 p-md-6">
			<div className="p-inputgroup">
				<Button
					label={questionId === 'url' ? 'URL' : 'Default Value'}
					tooltip="Pre-fill the response with a default value"
					tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
				/>
				<InputText
					disabled={!isEditable}
					autoComplete="off"
					value={valueDefault}
					id="default"
					placeholder=""
					onChange={(e) => updateOutput(e.target)}
				/>
			</div>
		</div>
	);
};

export default Default;
