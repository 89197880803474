import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react';
import { getExtractedKeywords } from '../../../services/keywords';
import { getSemanticSuggestions } from '../../../services/ontologies';
import { useDebounce } from '../../../utilities/hooks';
import TermHelperDialogForm from '../TermHelper/TermHelperDialogForm';

const SemanticTerm = ({
	label,
	isEditable,
	semantics,
	updateOutput,
	noSemanticTerm,
	setNoSemanticTerm,
}) => {
	const [isLoading, setIsLoading] = useState(true);
	const [proposedTerms, setProposedTerms] = useState([]);
	const [suggestions, setSuggestions] = useState([]);
	const [selectedTerm, setSelectedTerm] = useState(
		semantics && semantics.length > 0 ? semantics[0] : null
	);
	const debouncedLabelValue = useDebounce(label, 1000);

	const [termValue, setTermValue] = useState('');
	const [termSearch, setTermSearch] = useState('');
	const debouncedTermSearch = useDebounce(termSearch, 300);
	const [termHelperVisible, setTermHelperVisible] = useState(false);

	useEffect(() => {
		if (termSearch.length > 0) {
			getSemanticSuggestions(debouncedTermSearch)
				.then(({ data }) => setSuggestions(data || []))
				.catch(console.error);
		}
	}, [debouncedTermSearch]); // eslint-disable-line react-hooks/exhaustive-deps

	const fetchSuggestions = async (sentence) => {
		try {
			setIsLoading(true);
			const { data } = await getExtractedKeywords(sentence);
			setProposedTerms(data);
		} catch (err) {
			setProposedTerms([]);
			console.error(err);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		updateOutput({
			id: 'semantics',
			value: selectedTerm ? [selectedTerm] : [],
		});
		if (selectedTerm) {
			setNoSemanticTerm(false);
		}
  }, [selectedTerm]); // eslint-disable-line

	useEffect(() => {
		if (isEditable && Array.isArray(debouncedLabelValue) && debouncedLabelValue.length > 0) {
			fetchSuggestions(debouncedLabelValue[0]?.value);
		}
	}, [debouncedLabelValue, isEditable]);

	const termTemplate = ({ term, id, description, active, part_id: partID }) => {
		const visibleId = partID.toString().toLowerCase().includes('cus_') ? 'custom' : partID;

		return (
			<div
				style={{
					position: 'relative',
					display: 'inline-flex',
					flexDirection: 'column',
					whiteSpace: 'normal',
					width: '400px',
				}}
			>
				<div>
					<strong>{term}</strong>
					<span style={{ display: 'inline-block', marginLeft: '5px' }}>
						{active === 'true' ? (
							<>
								(
								<a href={id} target="_blank" rel="noreferrer">
									{visibleId}
								</a>
								)
							</>
						) : (
							`(${visibleId})`
						)}
					</span>
				</div>
				<div>
					<span style={{ whiteSpace: 'normal' }}>{description}</span>
				</div>
			</div>
		);
	};

	return (
		<div className="p-grid p-formgrid p-fluid">
			<div className="p-col-6">
				<h5>Term: {selectedTerm?.term || '-'}</h5>
				<AutoComplete
					id="terms"
					value={termValue}
					field="term"
					disabled={!isEditable}
					suggestions={suggestions}
					completeMethod={(e) => setTermSearch(e.query)}
					placeholder="Please start typing..."
					itemTemplate={termTemplate}
					onChange={(e) => {
						setTermValue(e.target.value);
						if (e?.target?.value === '') {
							setSelectedTerm(null);
						}
					}}
					onSelect={(e) => setSelectedTerm(e.value)}
				/>
				<div className="p-mt-3">
					<Checkbox
						inputId="no_semantic_term"
						disabled={!isEditable}
						onChange={(e) => {
							if (e.checked) {
								setTermValue('');
								setSelectedTerm(null);
							}
							setNoSemanticTerm(e);
						}}
						checked={noSemanticTerm}
					/>
					<label htmlFor="no_semantic_term" className="p-checkbox-label p-pl-2">
						This question is not relevant with any semantic term.
					</label>
				</div>
			</div>
			{isEditable && (
				<div className="p-col-6">
					<h5>Proposed Terms</h5>
					{debouncedLabelValue === undefined ? (
						<p style={{ height: '35px', lineHeight: '35px' }}>
							Please fill in a label for the question.
						</p>
					) : (
						<DataTable
							value={proposedTerms}
							rows={5}
							className="no-header-datatable"
							dataKey="id"
							rowHover
							selection={selectedTerm}
							onSelectionChange={(e) => setSelectedTerm(e.value)}
							loading={isLoading}
							emptyMessage="No terms found."
						>
							<Column
								header={null}
								selectionMode="single"
								style={{ height: '35px', width: '2rem', paddingBottom: 0, paddingTop: 0 }}
							/>
							<Column
								header={null}
								field="term"
								body={({ term }) => term || 'N/A'}
								style={{ paddingLeft: 0, paddingTop: '0.5rem', paddingBottom: '0.5rem' }}
							/>
						</DataTable>
					)}
				</div>
			)}
			<div className="p-col-12 p-mb-0 p-mt-4 p-py-0 p-text-right">
				<Button
					label="Use custom term"
					className="p-button-sm"
					icon="fa-duotone fa-input-text"
					style={{ width: 'auto' }}
					disabled={!isEditable}
					onClick={() => setTermHelperVisible(!termHelperVisible)}
				/>
				<TermHelperDialogForm
					visible={termHelperVisible}
					vocabulary="term"
					setVisible={setTermHelperVisible}
					onCreatedTerm={(term) => setSelectedTerm(term)}
				/>
			</div>
		</div>
	);
};

export default SemanticTerm;
