/* eslint-disable react/destructuring-assignment */
import { Button } from 'primereact/button';
import { ConfirmPopup } from 'primereact/confirmpopup';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { Fieldset } from 'primereact/fieldset';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import { QUESTION_MODE_NEW } from '../../../data/question-modes';
import { getAgronomicDates } from '../../../services/agronomic-dates';
import { searchQuestions } from '../../../services/questions';
import RequiredField from '../../RequiredField';
import Annotation from './Annotation';
import Calculation from './Calculation';
import Calendar from './Calendar';
import ChoiceFilter from './ChoiceFilter';
import Condition from './Condition';
import Constraint from './Constraint';
import DateType from './DateType';
import Default from './Default';
import ExcelName from './ExcelName';
import Hint from './Hint';
import Label from './Label';
import MatchingQueries from './MatchingQueries';
import Multiple from './Multiple';
import Note from './Note';
import Range from './Range';
import ReadOnly from './ReadOnly';
import Repeating from './Repeating';
import SemanticTerm from './SemanticTerm';
import Style from './Style';
import Trigger from './Trigger';
import Type from './Type';
import Variable from './Variable';
import Vocabularies from './Vocabularies';

const Question = (props) => {
	console.log(props);
	const [readOnly, setReadOnly] = useState(false);
	const [required, setRequired] = useState(false);
	const [repeating, setRepeating] = useState(false);
	const [repeatCount, setRepeatCount] = useState('');
	const [multiple, setMultiple] = useState('Single');
	const [typeDropdownValues, setTypeDropdownValues] = useState(null);
	const [typeSelect, setTypeSelect] = useState(null);
	const [styleDropdownValues, setStyleDropdownValues] = useState(null);
	const [styleSelect, setStyleSelect] = useState(null);
	const [vocabularySelect, setVocabularySelect] = useState(null);
	const [isEditable, setIsEditable] = useState(true);

	// Mode
	const [mode, setMode] = useState(null);

	// Values
	const [valueLabel, setValueLabel] = useState([]);
	const [valueName, setValueName] = useState(undefined);
	const [valueHint, setValueHint] = useState([]);
	const [valueDefault, setValueDefault] = useState(undefined);
	const [valueMin, setValueMin] = useState(undefined);
	const [valueMax, setValueMax] = useState(undefined);
	const [valueStep, setValueStep] = useState(undefined);
	const [valueNote, setValueNote] = useState(undefined);
	const [valueVariable, setValueVariable] = useState(undefined);

	const [valueCalculation, setValueCalculation] = useState(undefined);
	const [valueCondition, setValueCondition] = useState(undefined);
	const [valueConstraint, setValueConstraint] = useState(null);
	const [valueConstraintMessage, setValueConstraintMessage] = useState([]);
	const [valueTrigger, setValueTrigger] = useState(null);
	const [valueChoiceFilter, setValueChoiceFilter] = useState(null);

	// Autocomplete Semantics
	const [suggestedSemantics, setSuggestedSemantics] = useState(undefined);
	const [disableAddSearch, setDisableAddSearch] = useState(true);

	// Autocomplete Units
	const [valueUnits, setValueUnits] = useState(undefined);
	const [suggestedUnits, setSuggestedUnits] = useState(undefined);

	// Agronomic Dates.
	const [dateTypeDropdownValues, setDateTypeDropdownValues] = useState([]);
	const [dateTypeSelect, setDateTypeSelect] = useState(null);

	// Date Range
	const [dateFrom, setDateFrom] = useState(null);
	const [dateTo, setDateTo] = useState(null);

	// Range Inclusive
	const [minInclusive, setMinInclusive] = useState(false);
	const [maxInclusive, setMaxInclusive] = useState(false);

	const minOptions = [
		{ value: false, icon: 'fa-solid fa-bracket-round' },
		{ value: true, icon: 'fa-solid fa-bracket-square' },
	];

	const maxOptions = [
		{ value: false, icon: 'fa-solid fa-bracket-round-right' },
		{ value: true, icon: 'fa-solid fa-bracket-square-right' },
	];

	// Property Variables
	const [header, setHeader] = useState(null);
	const [questionId, setQuestionId] = useState(null);

	// Internal State
	const [visible, setVisible] = useState(false);

	// Output
	const [output, setOutput] = useState({});

	// Matching Queries
	const [source, setSource] = useState([]);
	const [target, setTarget] = useState([]);
	const [searchPopup, setSearchPopup] = useState(null);
	const [blockedMatchingQueries, setBlockedMatchingQueries] = useState(false);

	// Keywords Datatable
	const [extractedTerms, setExtractedTerms] = useState(null);
	const [extractedTerm, setExtractedTerm] = useState(false);
	const [selectedExtractedTerms, setSelectedExtractedTerms] = useState(null);
	const [, setDeleteExtractedTerms] = useState(false);
	const [deleteTermDialog, setDeleteTermDialog] = useState(false);
	const [deleteTermsDialog, setDeleteTermsDialog] = useState(false);

	const [, setSubmitted] = useState(false);
	const [displayKeywordsSearch, setDisplayKeywordsSearch] = useState(null);
	const [extractPopup, setExtractPopup] = useState(null);
	const [ontologyOptions, setOntologyOptions] = useState(null);
	const [selectedVocabulary, setSelectedVocabulary] = useState(null);
	const [searchTermDisabled, setSearchTermDisabled] = useState(true);

	const sliderValues = ['PKR', 'VLR', 'SLR'];

	// Semantic Term
	const [noSemanticTerm, setNoSemanticTerm] = useState(false);

	const toast = useRef(null);

	// Which question fields are available.
	const labelEnabled = props?.configuration?.basic_properties?.label?.enabled || false;
	const hintEnabled = props?.configuration?.basic_properties?.hint?.enabled || false;
	const excelNameEnabled = props?.configuration?.basic_properties?.name?.enabled || false;
	const isReadOnlyEnabled = props?.configuration?.basic_properties?.read_only?.enabled || false;
	const isReadOnlyRequired = props?.configuration?.basic_properties?.required?.enabled || false;
	const defaultEnabled = props?.configuration?.basic_properties?.default?.enabled || false;
	const rangeEnabled = props?.configuration?.basic_properties?.range?.enabled || false;
	const calendarEnabled = props?.configuration?.basic_properties?.calendar?.enabled || false;
	const styleEnabled = props?.configuration?.basic_properties?.style?.enabled || false;
	const typeEnabled = props?.configuration?.basic_properties?.type?.enabled || false;
	const typeHasUnit = props?.configuration?.basic_properties?.type?.unit || false;
	const multipleEnabled = props?.configuration?.basic_properties?.multiple?.enabled || false;
	const vocabulariesEnabled = props?.configuration?.basic_properties?.datatable?.enabled || false;
	const repeatingEnabled = props?.configuration?.basic_properties?.repeating?.enabled || false;
	const constraintEnabled = props?.configuration?.advanced_properties?.constraint?.enabled || false;
	const conditionEnabled = props?.configuration?.advanced_properties?.condition?.enabled || false;
	const needsSemanticTerms = props?.configuration?.needs_semantic_terms || false;
	const advancedCalculationEnabled =
    props?.configuration?.advanced_properties?.calculation?.enabled || false;
	const dateTypeEnabled = props?.configuration?.basic_properties?.datetype?.enabled || false;
	const noteEnabled = props?.configuration?.basic_properties?.note?.enabled || false;
	const variableEnabled = props?.configuration?.basic_properties?.variable?.enabled || false;
	const basicCalculationEnabled =
    props?.configuration?.basic_properties?.calculation?.enabled || false;
	const choiceFilterEnabled =
    (props?.configuration?.advanced_properties?.choice_filter?.enabled &&
      questionId === 'selection') ||
    false;
	const advancedPropertiesEnabled =
    advancedCalculationEnabled || constraintEnabled || conditionEnabled;

	const onVocabularyChange = ({ value }) => {
		setSelectedVocabulary(value || null);
		setSearchTermDisabled(!value);
	};

	const [loading, setLoading] = useState(true);

	const rightMatchingToolbarTemplate = () => {
		const accept = () => {
			setBlockedMatchingQueries(true);
			searchQuestions(valueLabel)
				.then(({ data }) => {
					const matchedIDs = target.map((matchedQuestion) => matchedQuestion.es_id);
					const filteredData = data.filter((item) => matchedIDs.indexOf(item.es_id) === -1);
					setSource(filteredData);
					setBlockedMatchingQueries(false);
					toast.current.show({
						severity: 'info',
						summary: 'Matching Questions',
						detail: 'Success',
						life: 3000,
					});
				})
				.catch(console.error);
		};

		return (
			<>
				<Toast ref={toast} />
				<ConfirmPopup
					target={document.getElementById('search')}
					visible={searchPopup}
					onHide={() => setSearchPopup(false)}
					message="Are you sure you want to proceed?"
					icon="fa-duotone fa-triangle-exclamation"
					accept={accept}
				/>
				<Button
					id="search"
					onClick={() => accept()}
					label="Search Questions"
					icon="fa-duotone fa-wand-sparkles"
					className="p-button-secondary"
					disabled={!(valueLabel && valueLabel.length > 3)}
				/>
			</>
		);
	};

	useEffect(() => {
		// Keywords Datatable
		setExtractedTerms([]);
		setLoading(false);

		// eslint-disable-next-line react/destructuring-assignment
		if (props.configuration) {
			const { configuration } = props;
			setQuestionId(configuration.general_properties.id);
			setHeader(configuration.general_properties.header);
			// eslint-disable-next-line react/destructuring-assignment
			setVisible(props.show);
			// eslint-disable-next-line react/destructuring-assignment
			setTypeDropdownValues(props.configuration.basic_properties.type.dropdownValues);
			// eslint-disable-next-line react/destructuring-assignment
			setStyleDropdownValues(props.configuration.basic_properties.style.dropdownValues);

			// eslint-disable-next-line react/destructuring-assignment
			if (props.data) {
				const { data } = props;
				console.log(data.constraint);
				setValueLabel(data.label);
				setValueName(data.name);
				setValueHint(data.hint);
				setValueDefault(data.default);
				setValueNote(data.note);
				setValueVariable(data.variable);
				setReadOnly(data.read_only);
				setRequired(data.required);
				setValueConstraint(data.constraint);
				setValueTrigger(data.trigger);
				setValueChoiceFilter(data.choice_filter);
				setValueMin(data.min);
				setValueMax(data.max);
				setDateFrom(data.range_date_from);
				setDateTo(data.range_date_to);
				setTarget([]);
				setMultiple(data?.multiple || 'Single');
				setRepeating(data.repeating);
				setRepeatCount(data.repeat_count);

				if (data?.is_editable === false) {
					setIsEditable(false);
				} else {
					// if property doesn't exist, reset it to true
					setIsEditable(true);
				}

				if (data.matching_questions) {
					setTarget(data.matching_questions);
				}

				if (data.min) {
					console.log(data?.min_inclusive);
					setMinInclusive(data?.min_inclusive);
				}

				if (data.max) {
					setMaxInclusive(data?.max_inclusive);
				}

				if (data.range_date_from) {
					console.log(data?.range_date_from);
					setMinInclusive(data?.min_from_inclusive);
				}

				if (data.range_date_to) {
					console.log(data?.range_date_to);
					setMaxInclusive(data?.max_to_inclusive);
				}

				if (data.style) {
					setStyleSelect(data.style);
				}

				if (data.vocabulary) {
					setVocabularySelect(data.vocabulary);
				}

				if (data.type) {
					setTypeSelect(data.type);
				}

				if (data.constraint_message) {
					setValueConstraintMessage(data.constraint_message);
				}

				if (data.trigger) {
					setValueTrigger(data.trigger);
				}

				if (data.choice_filter) {
					setValueChoiceFilter(data.choice_filter);
				}

				if (data.condition) {
					setValueCondition(data.condition);
				}

				if (data.calculation) {
					setValueCalculation(data.calculation);
				}

				if (data.semantics) {
					setExtractedTerms(data.semantics);
				}

				if (data.unit) {
					setValueUnits(data.unit);
				}

				if (data.datetype) {
					setDateTypeSelect(data.datetype);
				}

				if (data.no_semantic_term) {
					setNoSemanticTerm(data.no_semantic_term);
				}

				// eslint-disable-next-line react/destructuring-assignment
				setOutput({ ...data });
			} else {
				setMinInclusive(false);
				setMaxInclusive(false);
				setRequired(false);
				setReadOnly(false);
				setRepeating(false);
				setMultiple('Single');
				setStyleSelect(null);
				setTypeSelect(null);
				setVocabularySelect(null);

				// eslint-disable-next-line react/destructuring-assignment
				setOutput({});
			}
		}

		// eslint-disable-next-line react/destructuring-assignment
		if (props.mode) {
			// eslint-disable-next-line react/destructuring-assignment
			setMode(props.mode);
		}

		// eslint-disable-next-line react/destructuring-assignment
		if (props.ontologies) {
			setOntologyOptions(props.ontologies);
		}

		// Fetch agronomic dates when the relevant question type matches
		// and when the modal is shown only.
		if (dateTypeEnabled && props?.show) {
			getAgronomicDates()
				.then(({ data }) => setDateTypeDropdownValues(data || []))
				.catch(console.error);
		}
  }, [props.show]); // eslint-disable-line

	// Required fields.
	const [requiredFields, setRequiredFields] = useState([]);

	// Make the changes based on the question type.
	useEffect(() => {
		if (questionId === 'group') {
			if (!styleSelect) {
				updateOutput({ id: 'style', value: styleDropdownValues[0] });
			}
			setRequiredFields(['label', 'excel']);
		} else if (questionId === 'numeric') {
			if (!typeSelect) {
				updateOutput({ id: 'type', value: typeDropdownValues[0] });
			}
			if (!styleSelect) {
				updateOutput({ id: 'style', value: styleDropdownValues[0] });
			}
			setRequiredFields(['label', 'excel', 'type', 'unit']);
		} else if (questionId === 'calendar') {
			if (!styleSelect) {
				updateOutput({ id: 'style', value: styleDropdownValues[0] });
			}
			setRequiredFields(['label', 'excel', 'style', 'datetype']);
		} else if (questionId === 'location') {
			if (!typeSelect) {
				updateOutput({ id: 'type', value: typeDropdownValues[0] });
			}
			if (!styleSelect) {
				updateOutput({ id: 'style', value: styleDropdownValues[0] });
			}
			setRequiredFields(['label', 'excel', 'style', 'type']);
		} else if (questionId === 'selection') {
			if (!styleSelect) {
				updateOutput({ id: 'style', value: styleDropdownValues[0] });
			}
			setRequiredFields(['label', 'excel', 'style', 'vocabulary']);
		} else if (questionId === 'media') {
			if (!typeSelect) {
				updateOutput({ id: 'type', value: typeDropdownValues[0] });
			}
			setRequiredFields(['label', 'excel', 'type']);
		} else if (questionId === 'barcode') {
			setRequiredFields(['label', 'excel']);
		} else if (questionId === 'text') {
			setRequiredFields(['label', 'excel']);
		} else if (questionId === 'calculation') {
			setRequiredFields(['calculation', 'variable']);
		} else if (questionId === 'url') {
			setRequiredFields(['default']);
		}

		const semanticTermRequired = ['numeric', 'selection', 'text', 'location'].includes(questionId);

		if (!noSemanticTerm && semanticTermRequired) {
			setRequiredFields((rf) => [...rf, 'semantics']);
		}
  }, [questionId, noSemanticTerm]); // eslint-disable-line

	const shouldEnableQuestionButton = () => {
		const validationRules = {
			label: valueLabel?.length > 0,
			excel: valueName?.length > 0,
			type: typeSelect?.code?.length > 0,
			unit: valueUnits?.id?.length > 0,
			style: styleSelect?.code?.length > 0,
			vocabulary: vocabularySelect?.length > 0,
			datetype: dateTypeSelect?.id?.length > 0,
			semantics: extractedTerms?.length > 0,
			calculation: valueCalculation?.length > 0,
			variable: valueVariable?.length > 0,
			default: valueDefault?.length > 0,
		};

		return requiredFields.every((field) => validationRules[field]);
	};

	const updateOutput = (struct) => {
		if (struct.id === 'label') {
			setValueLabel(struct.value);
		}

		if (struct.id === 'name') {
			if (struct.value.length === 0) {
				setValueName(struct.value);
			} else {
				// eslint-disable-next-line no-param-reassign
				struct.value = struct.value.split(' ').join('_');
				setValueName(struct.value);
			}
		}

		const tempOutput = output;
		tempOutput[struct.id] = struct.value;

		if (struct.id === 'hint') {
			setValueHint(struct.value);
		}

		if (struct.id === 'range_date_from') {
			setDateFrom(struct.value);
			if (dateTo) {
				let noEqualMin = '>';
				let noEqualMax = '<';

				if (minInclusive) {
					noEqualMin = '>=';
				}
				if (maxInclusive) {
					noEqualMax = '<=';
				}

				const rangeExpression = `(.${noEqualMin}date('${struct.value}')) and (.${noEqualMax}date('${dateTo}'))`;
				tempOutput.constraint = rangeExpression;
				console.log(rangeExpression);
				setValueConstraint(rangeExpression);
			}
		}

		if (struct.id === 'range_date_to') {
			setDateTo(struct.value);

			if (dateFrom) {
				let noEqualMin = '>';
				let noEqualMax = '<';

				if (minInclusive) {
					noEqualMin = '>=';
				}
				if (maxInclusive) {
					noEqualMax = '<=';
				}

				const rangeExpression = `(.${noEqualMin}date('${dateFrom}')) and (.${noEqualMax}date('${struct.value}'))`;
				tempOutput.constraint = rangeExpression;
				console.log(rangeExpression);
				setValueConstraint(rangeExpression);
			}
		}

		if (struct.id === 'default') {
			setValueDefault(struct.value);
		}

		if (struct.id === 'note') {
			setValueNote(struct.value);
		}

		if (struct.id === 'variable') {
			setValueVariable(struct.value);
		}

		if (struct.id === 'type') {
			setTypeSelect(struct.value);
		}

		if (struct.id === 'style') {
			setStyleSelect(struct.value);
		}

		if (struct.id === 'vocabulary') {
			setVocabularySelect(struct.value);
			tempOutput.vocabulary = struct.value;
		}

		if (struct.id === 'step') {
			setValueStep(struct.value);
		}

		if (struct.id === 'min_from_inclusive') {
			if (struct.value === null) {
				setMinInclusive(false);
				tempOutput.min_from_inclusive = false;
			} else {
				console.log(struct.value);
				setMinInclusive(struct?.value);
				if (dateFrom && dateTo) {
					let noEqualMin = '>';
					if (struct.value) {
						noEqualMin = '>=';
					}
					let noEqualMax = '<';
					if (maxInclusive) {
						noEqualMax = '=<';
					}

					const rangeExpression = `(.${noEqualMin}date('${dateFrom}')) and (.${noEqualMax}date('${dateTo}'))`;
					setValueConstraint(rangeExpression);
					tempOutput.constraint = rangeExpression;
				}

				tempOutput.min_from_inclusive = struct.value;
			}
		}

		if (struct.id === 'max_to_inclusive') {
			if (struct.value === null) {
				setMaxInclusive(false);
				tempOutput.max_to_inclusive = false;
			} else {
				setMaxInclusive(struct?.value);
				if (dateFrom && dateTo) {
					let noEqualMin = '>';
					if (struct.value) {
						noEqualMin = '>=';
					}
					let noEqualMax = '<';
					if (maxInclusive) {
						noEqualMax = '=<';
					}

					const rangeExpression = `(.${noEqualMin}date('${dateFrom}')) and (.${noEqualMax}date('${dateTo}'))`;
					setValueConstraint(rangeExpression);
					tempOutput.constraint = rangeExpression;
				}

				tempOutput.max_to_inclusive = struct.value;
			}
		}

		if (struct.id === 'min_inclusive') {
			if (struct.value === null) {
				setMinInclusive(false);
				tempOutput.min_inclusive = false;
			} else {
				console.log(struct.value);
				setMinInclusive(struct?.value);
				if (valueMax && valueMin) {
					let noEqualMin = '>';
					if (struct.value) {
						noEqualMin = '>=';
					}
					let noEqualMax = '<';
					if (maxInclusive) {
						noEqualMax = '=<';
					}

					const rangeExpression = `(.${noEqualMin}${valueMin}) and (.${noEqualMax}${valueMax})`;
					setValueConstraint(rangeExpression);
					tempOutput.constraint = rangeExpression;
				}

				tempOutput.min_inclusive = struct.value;
			}
		}

		if (struct.id === 'max_inclusive') {
			if (struct.value === null) {
				setMaxInclusive(false);
				tempOutput.max_inclusive = false;
			} else {
				setMaxInclusive(struct?.value);
				if (valueMax && valueMin) {
					let noEqualMin = '>';
					if (minInclusive) {
						noEqualMin = '>=';
					}
					let noEqualMax = '<';
					if (struct.value) {
						noEqualMax = '=<';
					}

					const rangeExpression = `(.${noEqualMin}${valueMin}) and (.${noEqualMax}${valueMax})`;
					tempOutput.constraint = rangeExpression;
					setValueConstraint(rangeExpression);
				}
				tempOutput.max_inclusive = struct.value;
			}
		}

		if (struct.id === 'constraint') {
			setValueConstraint(struct.value);
		}

		if (struct.id === 'semantics') {
			setExtractedTerms(struct.value);
		}

		if (struct.id === 'constraint_message') {
			setValueConstraintMessage(struct.value);
		}

		if (struct.id === 'condition') {
			setValueCondition(struct.value);
		}

		if (struct.id === 'calculation') {
			setValueCalculation(struct.value);
		}

		if (struct.id === 'unit') {
			setValueUnits(struct.value);
		}

		if (struct.id === 'datetype') {
			setDateTypeSelect(struct.value);
		}

		if (struct.id === 'min') {
			setValueMin(struct.value);
			if (valueMax) {
				let noEqualMin = '>';
				if (minInclusive) {
					noEqualMin = '>=';
				}
				let noEqualMax = '<';
				if (maxInclusive) {
					noEqualMax = '<=';
				}

				const rangeExpression = `(.${noEqualMin}${struct.value}) and (.${noEqualMax}${valueMax})`;
				tempOutput.constraint = rangeExpression;
				tempOutput.min_inclusive = minInclusive;
				tempOutput.max_inclusive = maxInclusive;
				setValueConstraint(rangeExpression);
			}
		}

		if (struct.id === 'max') {
			setValueMax(struct.value);
			if (valueMin) {
				let noEqualMin = '>';
				if (minInclusive) {
					noEqualMin = '>=';
				}
				let noEqualMax = '<';
				if (maxInclusive) {
					noEqualMax = '<=';
				}
				const rangeExpression = `(.${noEqualMin}${valueMin}) and (.${noEqualMax}${struct.value})`;
				tempOutput.constraint = rangeExpression;
				tempOutput.min_inclusive = minInclusive;
				tempOutput.max_inclusive = maxInclusive;
				setValueConstraint(rangeExpression);
			}
		}

		setOutput({ ...tempOutput });
	};

	const resetQuestionValues = () => {
		setValueCondition('');
		setValueCalculation('');
		setValueConstraint('');
		setValueConstraintMessage('');
		setValueTrigger('');
		setValueChoiceFilter('');
		setValueUnits(undefined);
		setVisible(false);
		setNoSemanticTerm(false);
		setVocabularySelect(null);
		setValueDefault(undefined);
		setValueCalculation(undefined);
		setValueVariable(undefined);
	};

	const saveQuestion = () => {
		const tempOutput = output;
		tempOutput.id = questionId;

		if (required) {
			tempOutput.required = true;
		} else {
			tempOutput.required = false;
		}

		if (readOnly) {
			tempOutput.read_only = true;
		} else {
			tempOutput.read_only = false;
		}

		tempOutput.mode = mode;

		// eslint-disable-next-line react/destructuring-assignment
		if (mode === 'update') {
			// eslint-disable-next-line react/destructuring-assignment
			tempOutput.key = props.data.key;
		}

		tempOutput.semantics = extractedTerms;
		tempOutput.multiple = multiple;
		tempOutput.matching_questions = target;
		tempOutput.no_semantic_term = noSemanticTerm;
		tempOutput.repeating = repeating;
		tempOutput.repeat_count = repeatCount;
		tempOutput.datetype = dateTypeSelect;

		// Handle the variable case where there is no label.
		if (tempOutput.variable) {
			tempOutput.label = [
				{
					value: tempOutput.variable,
					language: {
						name: 'English',
						code: 'eng',
						code_1: 'en',
					},
				},
			];
			tempOutput.name = tempOutput.variable;
		}

		// eslint-disable-next-line react/destructuring-assignment
		props.output(tempOutput);

		// Reset the values for the next question.
		resetQuestionValues();

		setQuestionId(null);
	};

	const cancelQuestion = () => {
		// eslint-disable-next-line react/destructuring-assignment
		props.output(null);
		setVisible(false);

		// Reset the values for the next question.
		resetQuestionValues();
	};

	const itemTemplate = (item) => (
		<>
			<div style={{ maxWidth: '200px' }}>
				<div>
					<strong>{item.term}</strong>
				</div>
				<div>
					<span style={{ whiteSpace: 'normal' }}>{item.description}</span>
				</div>
			</div>
			<Divider />
		</>
	);

	const unitTemplate = (item) => (
		<>
			<div style={{ height: '60px', width: '200px' }}>
				<div>
					<strong>{item.term}</strong>
				</div>
				<div>
					<span style={{ whiteSpace: 'normal' }}>{item.description}</span>
				</div>
			</div>
			<Divider />
		</>
	);

	const renderFooter = () => (
		<div className="p-mt-4">
			<Button
				label="Cancel"
				icon="fa-duotone fa-xmark"
				onClick={() => cancelQuestion()}
				className="p-button-text p-button-danger"
			/>
			{!props.viewOnlyMode && (
				<Button
					className="p-button-success"
					label={mode === QUESTION_MODE_NEW ? 'Create' : 'Update'}
					icon={
						mode === QUESTION_MODE_NEW ? 'fa-duotone fa-check' : 'fa-duotone fa-arrow-rotate-right'
					}
					onClick={() => saveQuestion()}
					disabled={!shouldEnableQuestionButton()}
					autoFocus
				/>
			)}
		</div>
	);

	return (
		<Dialog
			header={header}
			visible={visible}
			maximizable
			closable
			modal
			style={{ width: '860px', maxWidth: '100%' }}
			footer={renderFooter()}
			onHide={cancelQuestion}
		>
			<Fieldset legend="Basic Properties" toggleable>
				<div className="p-grid p-fluid">
					<Variable
						isEditable={isEditable}
						enabled={variableEnabled}
						valueVariable={valueVariable}
						updateOutput={updateOutput}
					/>
					<Calculation
						isEditable={isEditable}
						enabled={basicCalculationEnabled}
						valueCalculation={valueCalculation}
						updateOutput={updateOutput}
						questionId={questionId}
					/>
					<Label
						defaultLanguage={props.language}
						isEditable={isEditable}
						enabled={labelEnabled}
						questionId={questionId}
						valueLabel={valueLabel}
						updateOutput={updateOutput}
						requiredFields={requiredFields}
						availableLanguages={props.languages}
					/>
					<Hint
						defaultLanguage={props.language}
						isEditable={isEditable}
						enabled={hintEnabled}
						valueHint={valueHint}
						updateOutput={updateOutput}
						availableLanguages={props.languages}
					/>
					<ExcelName
						isEditable={isEditable}
						enabled={excelNameEnabled}
						questionId={questionId}
						valueName={valueName}
						updateOutput={updateOutput}
					/>
					<Default
						isEditable={isEditable}
						enabled={defaultEnabled}
						valueDefault={valueDefault}
						updateOutput={updateOutput}
						questionId={questionId}
					/>
					<Calendar
						isEditable={isEditable}
						enabled={calendarEnabled}
						minInclusive={minInclusive}
						setMinInclusive={setMinInclusive}
						minOptions={minOptions}
						dateFrom={dateFrom}
						maxInclusive={maxInclusive}
						setMaxInclusive={setMaxInclusive}
						maxOptions={maxOptions}
						dateTo={dateTo}
						updateOutput={updateOutput}
					/>
					<ReadOnly
						type={props?.configuration?.type}
						isEditable={isEditable}
						enabled={isReadOnlyEnabled && isReadOnlyRequired}
						readOnly={readOnly}
						setReadOnly={setReadOnly}
						required={required}
						setRequired={setRequired}
					/>
					<Style
						isEditable={isEditable}
						enabled={styleEnabled}
						sliderValues={sliderValues}
						styleSelect={styleSelect}
						styleDropdownValues={styleDropdownValues}
						requiredFields={requiredFields}
						updateOutput={updateOutput}
						valueStep={valueStep}
						valueMax={valueMax}
						questionId={questionId}
						multiple={multiple}
					/>
					<Note
						isEditable={isEditable}
						enabled={noteEnabled}
						valueNote={valueNote}
						updateOutput={updateOutput}
					/>
					<DateType
						isEditable={isEditable}
						enabled={dateTypeEnabled}
						dateTypeDropdownValues={dateTypeDropdownValues}
						dateTypeSelect={dateTypeSelect}
						setDateTypeSelect={setDateTypeSelect}
						requiredFields={requiredFields}
					/>
					<Multiple
						isEditable={isEditable}
						enabled={multipleEnabled}
						multiple={multiple}
						setMultiple={setMultiple}
					/>
					<Vocabularies
						isEditable={isEditable}
						enabled={vocabulariesEnabled}
						vocabularies={props.vocabularies}
						vocabularySelect={vocabularySelect}
						requiredFields={requiredFields}
						updateOutput={updateOutput}
					/>
					<Repeating
						isEditable={isEditable}
						enabled={repeatingEnabled}
						repeating={repeating}
						repeatCount={repeatCount}
						setRepeating={setRepeating}
						setRepeatCount={setRepeatCount}
					/>
					<Type
						isEditable={isEditable}
						enabled={typeEnabled}
						hasUnit={typeHasUnit}
						typeSelect={typeSelect}
						typeDropdownValues={typeDropdownValues}
						valueUnits={valueUnits}
						setValueUnits={setValueUnits}
						suggestedUnits={suggestedUnits}
						setSuggestedUnits={setSuggestedUnits}
						unitTemplate={unitTemplate}
						updateOutput={updateOutput}
						requiredFields={requiredFields}
						questionId={questionId}
					/>
					<Range
						typeSelect={typeSelect}
						isEditable={isEditable}
						enabled={rangeEnabled}
						minInclusive={minInclusive}
						minOptions={minOptions}
						updateOutput={updateOutput}
						valueMin={valueMin}
						valueMax={valueMax}
						styleSelect={styleSelect}
						sliderValues={sliderValues}
						maxInclusive={maxInclusive}
						maxOptions={maxOptions}
						setMinInclusive={setMinInclusive}
						setMaxInclusive={setMaxInclusive}
					/>
				</div>
			</Fieldset>
			{false && (
				<Annotation
					toast={toast}
					extractedTerms={extractedTerms}
					selectedExtractedTerms={selectedExtractedTerms}
					setSelectedExtractedTerms={setSelectedExtractedTerms}
					loading={loading}
					displayKeywordsSearch={displayKeywordsSearch}
					setDisplayKeywordsSearch={setDisplayKeywordsSearch}
					selectedVocabulary={selectedVocabulary}
					ontologyOptions={ontologyOptions}
					onVocabularyChange={onVocabularyChange}
					searchTermDisabled={searchTermDisabled}
					valueSemantics={null}
					suggestedSemantics={suggestedSemantics}
					setSuggestedSemantics={setSuggestedSemantics}
					itemTemplate={itemTemplate}
					updateOutput={updateOutput}
					setDisableAddSearch={setDisableAddSearch}
					deleteTermDialog={deleteTermDialog}
					extractedTerm={extractedTerm}
					deleteTermsDialog={deleteTermsDialog}
					setExtractedTerms={setExtractedTerms}
					setDeleteTermsDialog={setDeleteTermsDialog}
					setDeleteExtractedTerms={setDeleteExtractedTerms}
					setExtractedTerm={setExtractedTerm}
					setDeleteTermDialog={setDeleteTermDialog}
					setSubmitted={setSubmitted}
					setLoading={setLoading}
					valueLabel={valueLabel}
					extractPopup={extractPopup}
					setExtractPopup={setExtractPopup}
					disableAddSearch={disableAddSearch}
				/>
			)}
			{false && (
				<MatchingQueries
					enabled
					rightMatchingToolbarTemplate={rightMatchingToolbarTemplate}
					blockedMatchingQueries={blockedMatchingQueries}
					source={source}
					target={target}
					setSource={setSource}
					setTarget={setTarget}
				/>
			)}
			{needsSemanticTerms && (
				<Fieldset
					legend={
						<div>
							<RequiredField />
							Semantic Term
						</div>
					}
					toggleable
					className="p-mt-4"
				>
					<SemanticTerm
						isEditable={
							isEditable ||
              (extractedTerms.length === 0 && props.data?.no_semantic_term === undefined)
						}
						semantics={extractedTerms}
						label={valueLabel}
						updateOutput={updateOutput}
						noSemanticTerm={noSemanticTerm}
						setNoSemanticTerm={(e) => setNoSemanticTerm(e.checked)}
					/>
				</Fieldset>
			)}
			{advancedPropertiesEnabled && (
				<Fieldset legend="Advanced Properties" toggleable className="p-mt-4">
					<div className="p-grid p-fluid">
						<Constraint
							isEditable={isEditable}
							defaultLanguage={props.language}
							enabled={constraintEnabled}
							valueConstraint={valueConstraint}
							valueConstraintMessage={valueConstraintMessage}
							updateOutput={updateOutput}
							availableLanguages={props.languages}
						/>
						<Condition
							isEditable={isEditable}
							enabled={conditionEnabled}
							valueCondition={valueCondition}
							updateOutput={updateOutput}
						/>
						<Calculation
							isEditable={isEditable}
							questionId={questionId}
							enabled={advancedCalculationEnabled}
							valueCalculation={valueCalculation}
							updateOutput={updateOutput}
						/>
						<Trigger
							isEditable={isEditable}
							enabled={advancedPropertiesEnabled}
							valueTrigger={valueTrigger}
							updateOutput={updateOutput}
						/>
						<ChoiceFilter
							isEditable={isEditable}
							enabled={choiceFilterEnabled}
							valueChoiceFilter={valueChoiceFilter}
							updateOutput={updateOutput}
						/>
					</div>
				</Fieldset>
			)}
		</Dialog>
	);
};

export default Question;
