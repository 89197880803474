import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tree } from 'primereact/tree';
import { useCallback, useState } from 'react';
import { createBlock } from '../../services/blocks';
import RequiredField from '../RequiredField';

const CreateBlockModal = ({ toast, visible, setVisible, blockData, setBlockData }) => {
	const [loading, setLoading] = useState(false);
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const [editableKeys, setEditableKeys] = useState([]);

	const resetAndHide = () => {
		setVisible(false);
		setName('');
		setDescription('');
		setEditableKeys([]);
		setBlockData(null);
		setLoading(false);
	};

	const updateEditableKeys = useCallback(
		(treeNodes) => {
			for (let i = 0; i < treeNodes.length; i += 1) {
				if (editableKeys[treeNodes[i]?.data?.key]?.checked) {
					// eslint-disable-next-line no-param-reassign
					treeNodes[i].data.is_editable = true;
				} else {
					// eslint-disable-next-line no-param-reassign
					treeNodes[i].data.is_editable = false;
				}

				if (treeNodes[i].children && treeNodes[i].children.length > 0) {
					updateEditableKeys(treeNodes[i].children);
				}
			}
			return treeNodes;
		},
		[editableKeys]
	);

	const saveBlock = async () => {
		setLoading(true);
		if (!blockData) return;
		const updatedNodes = updateEditableKeys([...blockData?.nodes]);
		// make the first node (which is a group) always editable
		updatedNodes[0].data.is_editable = true;
		try {
			await createBlock({
				nodes: updatedNodes,
				vocabularies: blockData?.vocabularies,
				metadata: {
					name,
					description,
				},
			});
			toast.current.show({
				severity: 'success',
				summary: 'Block created successfully',
				life: 3000,
			});
			resetAndHide();
		} catch (e) {
			toast.current.show({
				severity: 'error',
				summary: 'Something went wrong while saving this block',
				life: 3000,
			});
			setLoading(false);
		}
	};

	const footerTemplate = () => (
		<>
			<Button
				label="Cancel"
				icon="fa-duotone fa-xmark"
				onClick={() => resetAndHide()}
				className="p-button-text p-button-danger"
			/>
			<Button
				label="Create Block"
				disabled={loading || name.length === 0}
				loading={loading}
				icon="fa-duotone fa-plus"
				className="p-button-success"
				onClick={() => saveBlock()}
			/>
		</>
	);

	return (
		<Dialog
			header="Create Block"
			visible={visible}
			maximizable
			modal
			closable
			style={{ width: '800px' }}
			footer={footerTemplate()}
			onHide={() => resetAndHide()}
		>
			<div className="p-grid p-pt-1">
				<div className="p-col-12">
					<div className="p-inputgroup">
						<Button
							tooltip="Please provide a name to your block"
							tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
						>
							<RequiredField />
							Block Name
						</Button>
						<InputText
							value={name}
							id="name"
							onChange={(e) => setName(e.target.value)}
							autoComplete="off"
						/>
					</div>
				</div>
				<div className="p-col-12">
					<div className="p-inputgroup">
						<Button
							label="Block Description"
							tooltip="Please provide a meaningful description to your block"
							tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
						/>
						<InputText
							value={description}
							id="name"
							onChange={(e) => setDescription(e.target.value)}
							autoComplete="off"
						/>
					</div>
				</div>
				<div className="p-col-12">
					<p>
						We found <strong>{blockData?.vocabularies?.length || '0'}</strong> related vocabularies{' '}
						which will be saved with this block. Please only check the questions you want to be{' '}
						<strong>editable</strong> when you import this block. By default everything is{' '}
						<strong>non-editable</strong>.
					</p>
				</div>
				<div className="p-col-12">
					{blockData?.nodes.length > 0 && (
						<Tree
							value={blockData.nodes}
							selectionMode="checkbox"
							selectionKeys={editableKeys}
							onSelectionChange={(e) => setEditableKeys(e.value)}
						/>
					)}
				</div>
			</div>
		</Dialog>
	);
};

export default CreateBlockModal;
