import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

const ChoiceFilter = ({ enabled, isEditable, valueChoiceFilter, updateOutput }) => {
	if (!enabled) {
		return null;
	}

	return (
		<div className="p-col-12 p-md-12">
			<div className="p-inputgroup">
				<Button
					className="choice-filter-tooltip"
					label="Choice Filter"
					onClick={() => window.open('https://docs.getodk.org/form-logic/#cascading-selects')}
					tooltip="To limit the choice options based on the answer to a previous question. (click for more information)"
					tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
				/>
				<InputText
					disabled={!isEditable}
					value={valueChoiceFilter}
					id="choice_filter"
					placeholder=""
					onChange={(e) => updateOutput(e.target)}
				/>
			</div>
		</div>
	);
};

export default ChoiceFilter;
