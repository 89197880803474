import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { useState } from 'react';
import { importBlock, searchBlocks } from '../../services/blocks';

const SearchBlocksModal = ({ toast, visible, setVisible, onBlocksRefresh }) => {
	const [searchQuery, setSearchQuery] = useState('');
	const [loading, setLoading] = useState(false);
	const [importLoading, setImportLoading] = useState(false);
	const [blocks, setBlocks] = useState([]);

	const resetAndHide = () => {
		setVisible(false);
		setBlocks([]);
		setSearchQuery('');
	};

	const searchGlobalBlocks = async () => {
		setLoading(true);
		try {
			const { data } = await searchBlocks(searchQuery);
			setBlocks(data);
		} catch (error) {
			toast.current.show({
				life: 5000,
				severity: 'error',
				summary: 'Error',
				detail: 'Failed to search for blocks. Please try again.',
			});
		}
		setLoading(false);
	};

	const importGlobalBlock = async (uuid) => {
		setImportLoading(true);
		try {
			await importBlock(uuid);
			onBlocksRefresh();
			toast.current.show({
				severity: 'success',
				summary: 'Imported block',
				detail: 'The block was imported successfully.',
			});
			if (blocks.length === 1) {
				resetAndHide();
			}
		} catch (error) {
			toast.current.show({
				life: 5000,
				severity: 'error',
				summary: 'Error',
				detail: 'Failed to import block. Make sure that the imported block does not already exist.',
			});
		}
		setImportLoading(false);
	};

	return (
		<Dialog
			header="Search Global Blocks"
			visible={visible}
			maximizable
			modal
			closable
			style={{ minWidth: '600px' }}
			footer={null}
			onHide={() => resetAndHide()}
		>
			<div className="p-grid p-pt-1">
				<div className="p-col-12">
					<form
						className="p-fluid p-pb-2 p-grid p-formgrid"
						onSubmit={(e) => {
							e.preventDefault();
							searchGlobalBlocks();
						}}
					>
						<div className="p-col-9">
							<InputText
								placeholder="Enter search details..."
								value={searchQuery}
								onChange={(e) => setSearchQuery(e.target.value)}
							/>
						</div>
						<div className="p-col-3">
							<Button
								icon="fa-duotone fa-magnifying-glass"
								className="p-button-info"
								loading={loading || importLoading}
								label="Search"
								disabled={!searchQuery || loading}
								onClick={() => searchGlobalBlocks()}
							/>
						</div>
					</form>
					<div className="p-grid">
						<div className="p-col-12">
							{!loading &&
								blocks.length > 0 &&
								blocks.map(({ uuid, metadata }) => (
									<div className="p-mt-3" key={uuid}>
										<Panel collapsed header={metadata.name} toggleable>
											<div className="p-mb-2">
												<strong>Description</strong>
											</div>
											{metadata.description || 'N/A'}
											<div className="p-text-right">
												<Button
													icon="fa-duotone fa-file-import"
													label="Import"
													loading={importLoading}
													onClick={() => importGlobalBlock(uuid)}
												/>
											</div>
										</Panel>
									</div>
								))}
						</div>
					</div>
				</div>
			</div>
		</Dialog>
	);
};

export default SearchBlocksModal;
