import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';

const Note = ({ enabled, isEditable, valueNote, updateOutput }) => {
	if (!enabled) {
		return null;
	}

	return (
		<div className="p-col-12 p-md-12">
			<div className="p-inputgroup">
				<Button label="Note" />
				<InputTextarea
					disabled={!isEditable}
					autoComplete="off"
					value={valueNote}
					rows={4}
					id="note"
					onChange={(e) => updateOutput(e.target)}
				/>
			</div>
		</div>
	);
};

export default Note;
