import { Button } from 'primereact/button';
import MultilingualEntriesTable from '../MultilingualEntriesTable';

const Hint = ({
	enabled,
	isEditable,
	valueHint,
	updateOutput,
	availableLanguages,
	defaultLanguage,
}) => {
	if (!enabled) {
		return null;
	}

	const handleAddItem = (item) => {
		updateOutput({
			id: 'hint',
			value: valueHint ? valueHint.concat([item]) : [item],
		});
	};

	const handleDeleteItem = (langValue) => {
		updateOutput({
			id: 'hint',
			value: valueHint ? valueHint.filter((vl) => vl.language.code !== langValue) : [],
		});
	};

	const header = (
		<Button
			className="p-text-left"
			style={{ border: 'none', background: 'none', padding: 0 }}
			tooltip="Additional information for the enumerator and the respondent"
			tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
		>
			Hint
		</Button>
	);

	return (
		<div className="p-col-12 p-md-12">
			<MultilingualEntriesTable
				defaultLanguageCode={defaultLanguage?.code}
				isEditable={isEditable}
				className="custom-multilingual-entry"
				mode="edit"
				data={valueHint}
				onAddItem={handleAddItem}
				onDeleteItem={handleDeleteItem}
				header={header}
				availableLanguages={availableLanguages}
			/>
		</div>
	);
};

export default Hint;
