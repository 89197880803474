import { SelectButton } from 'primereact/selectbutton';
import React from 'react';

export const MULTIPLE = 'Multiple';
export const SINGLE = 'Single';

const Multiple = ({ enabled, isEditable, multiple, setMultiple }) => {
	if (!enabled) {
		return null;
	}

	const handleChange = (e) => {
		if (!e.value) return;
		setMultiple(e.value);
	};

	return (
		<div className="p-col-12 p-md-12">
			<SelectButton
				disabled={!isEditable}
				value={multiple}
				options={[SINGLE, MULTIPLE]}
				onChange={handleChange}
			/>
		</div>
	);
};

export default Multiple;
