import React from 'react';
import { Button } from 'primereact/button';
import './styles.css';

const MenuBar = (props) => {
	const { setCurrentPage } = props;
	return (
		<div className="menu-bar">
			<Button className="logo" label="DataScribe" onClick={() => setCurrentPage('home')} />
			<div className="pages">
				<Button label="About" onClick={() => setCurrentPage('about')} />
				<Button label="Contact" onClick={() => setCurrentPage('contact-us')} />
				<Button label="Sign in" onClick={() => setCurrentPage('login')} />
			</div>
		</div>
	);
};

export default MenuBar;
