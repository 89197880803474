import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { AutoComplete } from 'primereact/autocomplete';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useContext, useEffect, useState } from 'react';
import { createCustomTerm, getTermsCategories } from '../../../services/terms';
import { UserContext } from '../../../store';
import TermHelperIcon from './TermHelperIcon';
import TermHelperNonRequiredField from './TermHelperNonRequiredField';
import TermHelperRequiredField from './TermHelperRequiredField';

// Vocabulary can be one of:
// - term = Semantic Term
// - unit = Units
// - date = Dates

const TermHelperDialogForm = ({ vocabulary, visible, setVisible, onCreatedTerm }) => {
	const { firstname, lastname, email } = useContext(UserContext);

	const [loading, setLoading] = useState(false);
	const [term, setTerm] = useState('');
	const [description, setDescription] = useState('');
	const [fullname, setFullname] = useState(`${firstname} ${lastname}` || '');
	const [emailAddress, setEmailAddress] = useState(email || '');
	const [reference, setReference] = useState('');
	const [normalization, setNormalization] = useState(null);
	const [preOffset, setPreOffset] = useState(0);
	const [postOffset, setPostOffset] = useState(0);
	const [categories, setCategories] = useState([]);
	const [filteredCategories, setFilteredCategories] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState(null);

	const title = {
		term: 'Use custom term',
		unit: 'Use custom unit',
		date: 'Use custom date type',
	};

	const invalidForm = () => {
		const valid = !term || !description || !fullname || !emailAddress || !vocabulary;

		if (vocabulary === 'unit') {
			return valid || !normalization || !preOffset;
		}

		return valid;
	};

	const onSubmit = async () => {
		setLoading(true);

		try {
			const data = {
				term,
				description,
				submitter: { fullname, email },
				vocabulary,
				reference,
			};
			if (vocabulary === 'unit') {
				data.category = selectedCategory;
				data.factor = normalization;
				data.pre_offset = preOffset;
				data.post_offset = postOffset;
			}
			const response = await createCustomTerm(data);
			const customTerm = response?.data[0];
			if (onCreatedTerm) onCreatedTerm(customTerm);
			resetAndHide();
		} catch (error) {
			console.error(error);
		}

		setLoading(false);
	};

	const resetAndHide = () => {
		setLoading(false);
		setTerm('');
		setDescription('');
		setNormalization(null);
		setPreOffset(null);
		setPostOffset(null);
		setReference('');
		setSelectedCategory(null);
		setVisible(false);
	};

	useEffect(() => {
		(async () => {
			try {
				const { data } = await getTermsCategories();
				setCategories(data);
				setSelectedCategory('Mass Unit');
			} catch (e) {
				console.error(e);
			}
		})();
	}, []);

	const searchCategory = ({ query }) => {
		setFilteredCategories([...categories.filter((c) => c.category.startsWith(query))]);
	};

	const footerTemplate = () => (
		<div className="p-d-flex p-ai-center p-jc-between">
			<Button
				label="Cancel"
				icon="fa-duotone fa-xmark"
				onClick={resetAndHide}
				className="p-button p-button-secondary"
			/>
			<Button
				label="Create and use term"
				disabled={loading || invalidForm()}
				loading={loading}
				icon="fa-duotone fa-plus"
				className="p-button-success p-mr-0"
				onClick={onSubmit}
			/>
		</div>
	);

	return (
		<Dialog
			header={title[vocabulary]}
			visible={visible}
			modal
			closable
			style={{ width: '500px' }}
			footer={footerTemplate}
			onHide={resetAndHide}
		>
			<form className="p-formgrid p-my-1 p-grid p-fluid" onSubmit={onSubmit}>
				<div className="p-col-12">
					<label className="p-d-block" htmlFor="full_name">
						Full name
					</label>
					<div className="p-inputgroup p-field">
						<TermHelperIcon icon="fa-duotone fa-user" />
						<InputText
							id="full_name"
							value={fullname}
							placeholder="Full name"
							required
							onChange={(e) => setFullname(e.target.value)}
						/>
						<TermHelperRequiredField />
					</div>
				</div>
				<div className="p-col-12">
					<label className="p-d-block" htmlFor="email">
						Email
					</label>
					<div className="p-inputgroup p-field">
						<TermHelperIcon icon="fa-duotone fa-envelope" />
						<InputText
							id="email"
							placeholder="Email"
							value={emailAddress}
							required
							onChange={(e) => setEmailAddress(e.target.value)}
						/>
						<TermHelperRequiredField />
					</div>
				</div>
				<div className="p-col-12">
					<label className="p-d-block" htmlFor="term">
						New Term
					</label>
					<div className="p-inputgroup p-field">
						<TermHelperIcon icon="fa-duotone fa-input-text" />
						<InputText
							id="term"
							value={term}
							placeholder="Write down your term"
							required
							onChange={(e) => setTerm(e.target.value)}
						/>
						<TermHelperRequiredField />
					</div>
				</div>
				<div className="p-col-12">
					<label className="p-d-block" htmlFor="description">
						Description
					</label>
					<div className="p-inputgroup p-field">
						<TermHelperIcon icon="fa-duotone fa-text" />
						<InputTextarea
							id="description"
							value={description}
							placeholder="Write down a description for your term"
							required
							onChange={(e) => setDescription(e.target.value)}
						/>
						<TermHelperRequiredField />
					</div>
				</div>
				<div className="p-col-12">
					<label className="p-d-block" htmlFor="reference">
						Reference
					</label>
					<div className="p-inputgroup p-field">
						<TermHelperIcon icon="fa-duotone fa-link" />
						<InputText
							value={reference}
							id="reference"
							placeholder="Reference"
							required
							onChange={(e) => setReference(e.target.value)}
						/>
						<TermHelperNonRequiredField />
					</div>
				</div>
				{vocabulary === 'unit' && (
					<>
						<div className="p-col-12">
							<label className="p-d-block" htmlFor="categories">
								Category
							</label>
							<div className="p-inputgroup p-field">
								<TermHelperIcon icon="fa-duotone fa-layer-group" />
								<AutoComplete
									style={{ position: 'relative' }}
									id="categories"
									field="category"
									placeholder="Please start typing..."
									value={selectedCategory}
									suggestions={filteredCategories}
									completeMethod={searchCategory}
									onChange={(e) => setSelectedCategory(e.value)}
								/>
								<TermHelperRequiredField />
							</div>
						</div>
						<div className="p-col-12">
							<label className="p-d-block" htmlFor="factor">
								Factor (allowed any non-zero value)
							</label>
							<div className="p-inputgroup p-field">
								<TermHelperIcon icon="fa-duotone fa-chart-simple" />
								<InputNumber
									value={normalization}
									placeholder="Transformation factor to SI"
									id="factor"
									required
									mode="decimal"
									minFractionDigits={2}
									maxFractionDigits={5}
									onChange={(e) => setNormalization(e.value)}
								/>
								<TermHelperRequiredField />
							</div>
						</div>
						<div className="p-col-12">
							<label className="p-d-block" htmlFor="factor">
								Pre-offset (allowed any value)
							</label>
							<div className="p-inputgroup p-field">
								<TermHelperIcon icon="fa-duotone fa-plus-minus" />
								<InputNumber
									value={preOffset}
									placeholder="Pre-offset"
									required
									mode="decimal"
									minFractionDigits={2}
									maxFractionDigits={5}
									onChange={(e) => setPreOffset(e.value)}
								/>
								<TermHelperRequiredField />
							</div>
						</div>
						<div className="p-col-12">
							<label className="p-d-block" htmlFor="factor">
								Post-offset (allowed any value)
							</label>
							<div className="p-inputgroup p-field">
								<TermHelperIcon icon="fa-duotone fa-plus-minus" />
								<InputNumber
									value={postOffset}
									placeholder="Post-offset"
									required
									mode="decimal"
									minFractionDigits={2}
									maxFractionDigits={5}
									onChange={(e) => setPostOffset(e.value)}
								/>
								<TermHelperRequiredField />
							</div>
						</div>
						<div className="p-col-12">
							<strong>STANDARD UNIT = ( ( MY_UNIT + Pre-offset) * Factor) + Post-offset</strong>
						</div>
					</>
				)}
			</form>
		</Dialog>
	);
};

export default TermHelperDialogForm;
