import { nanoid } from 'nanoid';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { useEffect, useState } from 'react';
import VocabularyChoicesTable from './VocabularyChoicesTable';

const DEFAULT_LANGUAGE_CODE = 'eng';
export const DEFAULT_COLUMNS = ['name', 'termURL', 'label'];

const VocabularyModal = ({
	languages,
	internalId,
	vocabulary,
	onCreate,
	onUpdate,
	visible,
	setVisible,
	viewOnlyMode = false,
	onlyDelete = false,
}) => {
	const [name, setName] = useState(vocabulary?.listname || '');
	const [description, setDescription] = useState(vocabulary?.description || '');
	const [language, setLanguage] = useState(vocabulary?.language || DEFAULT_LANGUAGE_CODE);
	const [choices, setChoices] = useState(vocabulary?.choices || []);
	const [columns, setColumns] = useState(vocabulary?.columns || DEFAULT_COLUMNS);
	const [creatingChoice, setCreatingChoice] = useState(false);

	const selectedLanguage = languages.filter((l) => language === l.code)?.pop()?.name || null;

	const resetState = () => {
		setName('');
		setDescription('');
		setLanguage(DEFAULT_LANGUAGE_CODE);
		setChoices([]);
	};

	useEffect(() => {
		setName(vocabulary?.listname || '');
		setDescription(vocabulary?.description || '');
		setChoices(vocabulary?.choices || []);
		setLanguage(vocabulary?.language || DEFAULT_LANGUAGE_CODE);
		setColumns(vocabulary?.columns || DEFAULT_COLUMNS);
		return () => resetState();
	}, [vocabulary]);

	const footerTemplate = () => (
		<>
			<Button
				label="Cancel"
				icon="fa-duotone fa-xmark"
				onClick={() => setVisible(false)}
				className="p-button-text p-button-danger"
			/>
			{viewOnlyMode === false && (
				<Button
					label={vocabulary ? 'Save' : 'Create'}
					icon="fa-duotone fa-plus"
					disabled={choices?.length === 0 || name?.length === 0 || creatingChoice}
					className="p-button-success"
					onClick={() => {
						const v = {
							id: internalId,
							body: {
								...vocabulary,
								id: vocabulary?.id || nanoid(),
								listname: name,
								description,
								isGlobal: false,
								language,
								choices,
								columns,
							},
						};
						vocabulary ? onUpdate(v) : onCreate(v);
						resetState();
						setVisible(false);
					}}
				/>
			)}
		</>
	);

	return (
		<Dialog
			header="Add / Edit a choice list"
			visible={visible}
			maximizable
			modal
			closable
			style={{ width: '1000px', maxWidth: '95%' }}
			footer={footerTemplate}
			onHide={() => setVisible(false)}
		>
			<div className="p-grid p-fluid">
				<div className="p-col-12">
					<div className="p-inputgroup">
						<Button
							label="Choice list name"
							tooltip="Please provide a name to your choice list"
							tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
						/>
						<InputText
							value={name}
							disabled={onlyDelete}
							id="list_name"
							onChange={(e) => setName(e.target.value)}
							autoComplete="off"
						/>
					</div>
					{name?.length === 0 && (
						<Message
							className="p-mt-2 p-text-left custom"
							severity="error"
							text="The list name is mandatory"
						/>
					)}
				</div>
				<div className="p-col-12">
					<div className="p-inputgroup">
						<Button
							label="Description"
							tooltip="Please provide a meaningful description to your choice list"
							tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
						/>
						<InputText
							value={description}
							disabled={onlyDelete}
							id="description"
							onChange={(e) => setDescription(e.target.value)}
							autoComplete="off"
						/>
					</div>
				</div>
				<div className="p-col-12">
					<div className="p-inputgroup">
						<Button
							label="Language"
							tooltip="Please choose a language for the choice list"
							tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
						/>
						<Dropdown
							options={languages}
							optionLabel="name"
							optionValue="code"
							filter
							filterBy="name"
							required
							value={language}
							onChange={(e) => setLanguage(e.value)}
						/>
					</div>
				</div>
				<VocabularyChoicesTable
					language={selectedLanguage}
					columns={columns}
					setColumns={setColumns}
					defaultColumns={DEFAULT_COLUMNS}
					onlyDelete={onlyDelete}
					choices={choices}
					setChoices={setChoices}
					creatingChoice={creatingChoice}
					setCreatingChoice={setCreatingChoice}
				/>
			</div>
		</Dialog>
	);
};

export default VocabularyModal;
