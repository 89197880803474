import { nanoid } from 'nanoid';
import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ConfirmPopup } from 'primereact/confirmpopup';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Fieldset } from 'primereact/fieldset';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { useEffect, useState } from 'react';
import { getExtractedKeywords } from '../../../services/keywords';
import { getSemanticSuggestions } from '../../../services/ontologies';
import { useDebounce } from '../../../utilities/hooks';

const Annotation = ({
	toast,
	extractedTerms,
	selectedExtractedTerms,
	setSelectedExtractedTerms,
	loading,
	displayKeywordsSearch,
	setDisplayKeywordsSearch,
	selectedVocabulary,
	ontologyOptions,
	onVocabularyChange,
	searchTermDisabled,
	valueSemantics,
	suggestedSemantics,
	setSuggestedSemantics,
	itemTemplate,
	updateOutput,
	setDisableAddSearch,
	deleteTermDialog,
	extractedTerm,
	deleteTermsDialog,
	setExtractedTerms,
	setDeleteTermsDialog,
	setDeleteExtractedTerms,
	setExtractedTerm,
	setDeleteTermDialog,
	setSubmitted,
	setLoading,
	valueLabel,
	extractPopup,
	setExtractPopup,
	disableAddSearch,
}) => {
	const [keywordSearch, setKeywordSearch] = useState('');
	const debouncedKeywordSearch = useDebounce(keywordSearch, 300);

	useEffect(() => {
		getSemanticSuggestions(debouncedKeywordSearch)
			.then(({ data }) => setSuggestedSemantics(data || []))
			.catch(console.error);
	}, [debouncedKeywordSearch, setSuggestedSemantics]);

	const addKeyword = () => {
		setSubmitted(true);

		const tempExtractedTerms = extractedTerms.concat(valueSemantics);

		toast.current.show({
			severity: 'success',
			summary: 'Success',
			detail: 'Term Added',
			life: 3000,
		});

		setExtractedTerms(tempExtractedTerms);
		setDisplayKeywordsSearch(false);
		setExtractedTerm({ id: null, term: '', scheme: '', description: '' });
	};

	const renderFooterKeywordSearch = () => (
		<div>
			<Button
				label="Cancel"
				icon="fa-duotone fa-xmark"
				onClick={() => setDisplayKeywordsSearch(false)}
				className="p-button-text p-button-danger"
			/>
			<Button
				label="Add"
				icon="fa-duotone fa-plus"
				onClick={() => {
					addKeyword();
				}}
				className="p-button-success"
				autoFocus
				disabled={disableAddSearch}
			/>
		</div>
	);

	const hideDeleteTermDialog = () => {
		setDeleteTermDialog(false);
	};

	const hideDeleteTermsDialog = () => {
		setDeleteTermsDialog(false);
	};

	const confirmDeleteTermRecord = (termRecord) => {
		setExtractedTerm(termRecord);
		setDeleteTermDialog(true);
	};

	const actionBodyTemplate = (termRecord) => (
		<Button
			icon="fa-duotone fa-trash"
			className="p-button-danger"
			onClick={() => confirmDeleteTermRecord(termRecord)}
		/>
	);

	const confirmDeleteSelectedTerms = () => {
		setDeleteTermsDialog(true);
	};

	const leftToolbarTemplate = () => (
		<>
			<Button
				label="Add"
				icon="fa-duotone fa-plus"
				className="p-button-success p-mr-2"
				onClick={() => {
					setExtractedTerm({ id: null, term: '', scheme: '', description: '' });
					setSubmitted(false);
					setDisplayKeywordsSearch(true);
				}}
			/>
			<Button
				label="Delete"
				icon="fa-duotone fa-trash"
				className="p-button-danger"
				onClick={confirmDeleteSelectedTerms}
				disabled={!selectedExtractedTerms || !selectedExtractedTerms.length}
			/>
		</>
	);

	const rightToolbarTemplate = () => {
		const accept = () => {
			setLoading(true);
			getExtractedKeywords(valueLabel)
				.then(({ data }) => {
					const tempExtractedTerms = data.map((item) => {
						// eslint-disable-next-line no-param-reassign
						item.uri = item.id;
						// eslint-disable-next-line no-param-reassign
						item.id = nanoid();
						return item;
					});
					setLoading(false);
					const mergedExtractedTerms = [...extractedTerms, ...tempExtractedTerms];
					setExtractedTerms([...mergedExtractedTerms]);
				})
				.catch(console.error);
		};

		return (
			<>
				<Toast ref={toast} />
				<ConfirmPopup
					target={document.getElementById('extract')}
					visible={extractPopup}
					onHide={() => setExtractPopup(false)}
					message="Are you sure you want to proceed?"
					icon="fa-duotone fa-triangle-exclamation"
					accept={accept}
				/>
				<Button
					id="extract"
					onClick={() => accept()}
					label="Extract Keywords"
					icon="fa-duotone fa-wand-sparkles"
					className="p-button-secondary"
					disabled={!(valueLabel && valueLabel.length > 3)}
				/>
			</>
		);
	};

	const deleteSelectedTerms = () => {
		const tempExtractedTerms = extractedTerms.filter(
			(val) => !selectedExtractedTerms.includes(val)
		);
		setExtractedTerms(tempExtractedTerms);
		setDeleteTermsDialog(false);
		setSelectedExtractedTerms(null);
	};

	const deleteTermRecord = () => {
		const tempExtractedTerms = extractedTerms.filter((val) => val.id !== extractedTerm.id);
		setExtractedTerms(tempExtractedTerms);
		setDeleteExtractedTerms(false);
		setExtractedTerm({ id: null, term: '', scheme: '', description: '' });
		setDeleteTermDialog(false);
	};

	const deleteTermsDialogFooter = (
		<>
			<Button
				label="No"
				icon="fa-duotone fa-xmark"
				className="p-button-text p-button-success mr-2"
				onClick={hideDeleteTermsDialog}
			/>
			<Button
				label="Yes"
				icon="fa-duotone fa-trash"
				className="p-button-danger"
				onClick={deleteSelectedTerms}
			/>
		</>
	);

	const deleteTermDialogFooter = (
		<>
			<Button
				label="No"
				icon="fa-duotone fa-xmark"
				className="p-button-text p-button-success mr-2"
				onClick={hideDeleteTermDialog}
			/>
			<Button
				label="Yes"
				icon="fa-duotone fa-trash"
				className="p-button-danger"
				onClick={deleteTermRecord}
			/>
		</>
	);

	return (
		<Fieldset legend="Semantics" toggleable className="p-mt-4">
			<Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate} />
			<DataTable
				value={extractedTerms}
				paginator
				className="p-datatable-customers"
				rows={10}
				dataKey="id"
				rowHover
				selection={selectedExtractedTerms}
				onSelectionChange={(e) => setSelectedExtractedTerms(e.value)}
				loading={loading}
				responsiveLayout="scroll"
				emptyMessage="No terms found."
			>
				<Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
				<Column field="term" header="Term" sortable style={{ minWidth: '14rem' }} />
				<Column field="description" header="Description" sortable style={{ minWidth: '14rem' }} />
				<Column field="scheme" header="Scheme" sortable style={{ minWidth: '14rem' }} />
				<Column
					headerStyle={{ width: '4rem', textAlign: 'center' }}
					bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
					body={actionBodyTemplate}
				/>
			</DataTable>
			<Dialog
				header="Search Terms"
				visible={displayKeywordsSearch}
				style={{ width: '50vw' }}
				closable
				maximizable
				footer={renderFooterKeywordSearch()}
				onHide={() => setDisplayKeywordsSearch(false)}
			>
				<div className="p-grid p-fluid">
					<div className="p-col-12 p-md-6">
						<div>
							<Button label="Select Ontology" />
						</div>
						<div className="p-inputgroup">
							<Dropdown
								value={selectedVocabulary}
								options={ontologyOptions}
								onChange={onVocabularyChange}
								optionLabel="scheme"
								placeholder="Select a Vocabulary"
								filter
								showClear
								filterBy="scheme"
							/>
						</div>
					</div>
					<div className="p-col-12 p-md-6">
						<div>
							<Button label="Search Terms" />
						</div>
						<div className="p-inputgroup">
							<AutoComplete
								forceSelection
								disabled={searchTermDisabled}
								id="semantics"
								value={valueSemantics}
								field="term"
								suggestions={suggestedSemantics}
								completeMethod={(e) => setKeywordSearch(e.query)}
								placeholder="Please start typing ..."
								itemTemplate={itemTemplate}
								onChange={(e) => updateOutput(e.target)}
								onSelect={(e) => setDisableAddSearch(false)}
							/>
						</div>
					</div>
				</div>
			</Dialog>
			<Dialog
				visible={deleteTermDialog}
				style={{ width: '450px' }}
				header="Confirm"
				modal
				closable
				maximizable
				footer={deleteTermDialogFooter}
				onHide={hideDeleteTermDialog}
			>
				<div className="confirmation-content">
					<i className="fa-duotone fa-triangle-exclamation mr-3" style={{ fontSize: '2rem' }} />
					{extractedTerm && (
						<span className="p-ml-3">
							Are you sure you want to delete <b>{extractedTerm.term}</b>?
						</span>
					)}
				</div>
			</Dialog>
			<Dialog
				visible={deleteTermsDialog}
				style={{ width: '450px' }}
				header="Confirm"
				modal
				closable
				maximizable
				footer={deleteTermsDialogFooter}
				onHide={hideDeleteTermsDialog}
			>
				<div className="confirmation-content">
					<i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
					{extractedTerms && <span>Are you sure you want to delete the selected terms?</span>}
				</div>
			</Dialog>
		</Fieldset>
	);
};

export default Annotation;
