import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import QuestionnaireForm from '../components/Questionnaire';
import { getQuestionnaire } from '../../../services/questionnaires';

const EditQuestionnaire = () => {
	const { id } = useParams();
	const history = useHistory();
	const [qs, setQs] = useState(null);

	useEffect(() => {
		getQuestionnaire(id)
			.then(({ data: d }) => setQs(d))
			.catch((e) => {
				console.error(e);
				history.push('/');
			});
	}, [id, history]);

	if (qs === null) return <></>;

	return (
		<QuestionnaireForm data={qs} />
	);
};

export default EditQuestionnaire;
