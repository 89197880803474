import apiClient from '../utilities/api-client';

export const getVocabularies = async () => apiClient.get(`/vocabularies`);

export const getMultipleVocabularies = async (ids) =>
	apiClient.get(`/vocabularies/multiple?ids=${ids}`);

export const getVocabulary = async (id) => apiClient.get(`/vocabularies/${id}`);

export const createVocabulary = async (body) => apiClient.post('/vocabularies', body);

export const updateVocabulary = async (id, body) => apiClient.put(`/vocabularies/${id}`, body);

export const deleteVocabulary = async (id) => apiClient.remove(`/vocabularies/${id}`);

export const publishVocabulary = async (id) => apiClient.post(`/vocabularies/${id}/publish`);

export const searchVocabularies = async (term) => apiClient.post(`/vocabularies/search`, { term });

export const importVocabulary = async (uuid) =>
	apiClient.post(`/vocabularies/import`, { external_id: uuid });
