import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';

const Condition = ({ enabled, isEditable, valueCondition, updateOutput }) => {
	if (!enabled) {
		return null;
	}

	return (
		<div className="p-col-12">
			<div className="p-inputgroup">
				<Tooltip target=".relevant-tooltip">
					Define the conditions under which the question should appear, based on the values of other
					questions.
					<br /> (click for more information)
				</Tooltip>
				<Button
					className="relevant-tooltip"
					label="Relevant"
					onClick={() =>
						window.open(
							'https://docs.getodk.org/form-logic/#conditionally-showing-questions',
							'_blank',
							'noopener,noreferrer'
						)
					}
				/>
				<InputText
					disabled={!isEditable}
					value={valueCondition}
					id="condition"
					placeholder=""
					onChange={(e) => updateOutput(e.target)}
				/>
			</div>
		</div>
	);
};

export default Condition;
