import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Fieldset } from 'primereact/fieldset';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';

const QuestionnaireSettings = ({
	title,
	setTitle,
	viewOnlyMode,
	languageValue,
	setLanguageValue,
	languageValues,
	version,
	setVersion,
	updateOption,
	options,
}) => (
	<div className="p-grid p-fluid">
		<div className="p-col-12 p-md-12">
			<div className="p-inputgroup">
				<Button
					label="Questionnaire Title"
					tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
					tooltip="How this questionnaire will be named on the enumerator application."
				/>
				<InputText
					value={title}
					id="title"
					disabled={viewOnlyMode}
					onChange={(e) => setTitle(e.target.value)}
				/>
			</div>
			{title?.length === 0 && (
				<Message
					className="p-mt-3 p-text-left custom"
					severity="error"
					text="The title field is mandatory."
				/>
			)}
		</div>

		<div className="p-col-12 p-md-6">
			<div className="p-inputgroup">
				<Button label="Language" />
				<Dropdown
					optionLabel="name"
					filter
					showClear
					disabled={viewOnlyMode}
					filterBy="name"
					value={languageValue}
					onChange={(e) => setLanguageValue(e.value)}
					options={languageValues}
					placeholder="Select a language"
					className="multiselect-custom"
				/>
			</div>
		</div>

		<div className="p-col-12 p-md-6">
			<div className="p-inputgroup">
				<Button
					label="Version"
					tooltip="This is used to help differentiate two questionnaires with a same title."
					tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
				/>
				<InputNumber
					showButtons
					buttonLayout="horizontal"
					decrementButtonClassName="p-button-secondary"
					incrementButtonClassName="p-button-secondary"
					incrementButtonIcon="fa-duotone fa-plus"
					decrementButtonIcon="fa-duotone fa-minus"
					autocomplete="off"
					disabled={viewOnlyMode}
					useGrouping={false}
					value={version}
					min={1}
					mode="decimal"
					id="version"
					onValueChange={(e) => setVersion(e.target.value)}
				/>
			</div>
		</div>

		<div className="p-col-12">
			<Fieldset
				legend={
					<Button
						label="Metadata"
						style={{
							background: 'none',
							border: 'none',
							padding: 0,
							color: '#495057',
							fontWeight: '600',
						}}
						className="custom-label"
						tooltip="Metadata questions silently and automatically collect information about the data collection session."
						tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
					/>
				}
			>
				<div className="p-grid p-fluid">
					<div className="p-col-2">
						<Checkbox
							inputId="start"
							value="start"
							tooltip="A timestamp of when the form entry starts."
							tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
							onChange={(e) => updateOption('start', e.checked)}
							checked={options.start}
						/>
						<label htmlFor="start" className="p-checkbox-label p-ml-2">
							Start
						</label>
					</div>
					<div className="p-col-2">
						<Checkbox
							inputId="end"
							value="end"
							tooltip="A timestamp of when the form entry ends."
							tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
							onChange={(e) => updateOption('end', e.checked)}
							checked={options.end}
						/>
						<label htmlFor="end" className="p-checkbox-label p-ml-2">
							End
						</label>
					</div>
					<div className="p-col-2">
						<Checkbox
							inputId="today"
							value="today"
							tooltip="A timestamp of today's local date. Useful if data collection runs over multiple days."
							tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
							onChange={(e) => updateOption('today', e.checked)}
							checked={options.today}
						/>
						<label htmlFor="today" className="p-checkbox-label p-ml-2">
							Today
						</label>
					</div>
					<div className="p-col-2">
						<Checkbox
							inputId="userid"
							value="userid"
							tooltip="The username stored in the enumerator device, when available."
							tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
							onChange={(e) => updateOption('userid', e.checked)}
							checked={options.userid}
						/>
						<label htmlFor="userid" className="p-checkbox-label p-ml-2">
							User ID
						</label>
					</div>
					{/* <div className="p-col-2">
            <Checkbox
              inputId="subscriberid"
              value="subscriberid"
              onChange={(e) => updateOption('subscriberid', e.checked)}
              checked={options.subscriberid}
            />
            <label htmlFor="subscriberid" className="p-checkbox-label p-ml-2">
              Subscriber ID
            </label>
          </div> */}
					<div className="p-col-2">
						<Checkbox
							inputId="email"
							value="email"
							tooltip="The enumerator's email address when available."
							tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
							onChange={(e) => updateOption('email', e.checked)}
							checked={options.email}
						/>
						<label htmlFor="email" className="p-checkbox-label p-ml-2">
							Email
						</label>
					</div>
					<div className="p-col-2">
						<Checkbox
							inputId="phonenumber"
							value="phonenumber"
							tooltip="The phone number of the enumerator device, when available."
							tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
							onChange={(e) => updateOption('phonenumber', e.checked)}
							checked={options.phonenumber}
						/>
						<label htmlFor="phonenumber" className="p-checkbox-label p-ml-2">
							Phone Number
						</label>
					</div>
				</div>
			</Fieldset>
		</div>
	</div>
);

export default QuestionnaireSettings;
