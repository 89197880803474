import Gleap from 'gleap';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import AppWrapper from './AppWrapper';
import './utilities/i18n-next';

// Initialize Gleap once.
if (process.env.REACT_APP_GLEAP_ID) {
	if (process.env.REACT_APP_ENVIRONMENT !== 'dev') {
		Gleap.initialize(process.env.REACT_APP_GLEAP_ID);
	}
}

ReactDOM.render(
	<HashRouter>
		<AppWrapper />
	</HashRouter>,
	document.getElementById('root')
);
