import apiClient from '../utilities/api-client';

export const getQuestionnaires = async () => apiClient.get(`/questionnaires`);

export const getQuestionnaire = async (id) => apiClient.get(`/questionnaires/${id}`);

export const createQuestionnaire = async (body) => apiClient.post('/questionnaires', body);

export const updateQuestionnaire = async (id, body) => apiClient.put(`/questionnaires/${id}`, body);

export const deleteQuestionnaire = async (id) => apiClient.remove(`/questionnaires/${id}`);

export const cloneQuestionnaire = async (id) => apiClient.post(`/questionnaires/${id}/clone`);

export const downloadQuestionnaireAsXLSForm = async (id) =>
	apiClient.get(`/questionnaires/${id}/download`);

export const getPreviewQuestionnaireLink = async (id) => apiClient.get(`/preview-link/${id}`);

export const downloadCarobScript = async (id) =>
	apiClient.get(`/questionnaires/${id}/download_carob_script`);

export const transferQuestionnaireOwnership = async (id, newOwnerId) =>
	apiClient.post(`/questionnaires/${id}/change_ownership`, { owner_id: newOwnerId });

export const importQuestionnaire = async (data) =>
	apiClient.post(`/questionnaires/import`, data, {
		'Content-Type': 'multipart/form-data',
	});
