import React from 'react';
import annotate from '../../../../assets/loginpage/annotate.png';
import build from '../../../../assets/loginpage/build.png';
import collaborate from '../../../../assets/loginpage/collaborate.png';
import createTemplates from '../../../../assets/loginpage/createTemplates.png';
import library from '../../../../assets/loginpage/library.png';
import link from '../../../../assets/loginpage/link.png';
import reuse from '../../../../assets/loginpage/reuse.png';
import test from '../../../../assets/loginpage/test.png';
import translate from '../../../../assets/loginpage/translate.png';
import background from '../../../../assets/loginpage/background.png';
import './styles.css';

const Home = () => {
	const displayedData = [
		{
			img: reuse,
			text: 'Reuse standard survey forms shared by scientists',
		},
		{
			img: library,
			text: 'Use a library of questions and choice lists tailored for the agricultural domain',
		},
		{
			img: createTemplates,
			text: 'Create your own templates from scratch or by uploading XLSforms',
		},
		{
			img: build,
			text: 'Easily build in quantitative constraints to ensure high data quality',
		},
		{
			img: annotate,
			text: 'Annotate variables with community standards',
		},
		{
			img: link,
			text: 'Link variables with standard units',
		},
		{
			img: collaborate,
			text: 'Collaborate with team members',
		},
		{
			img: translate,
			text: 'Translate forms into multiple languages',
		},
		{
			img: test,
			text: 'Test your forms online',
		},
	];

	const renderCards = () => {
		return displayedData.map((item) => {
			return (
				<div className="home-card">
					<img src={item.img} />
					<p>{item.text}</p>
				</div>
			);
		});
	};

	return (
		<div className="home">
			<div className="upper">
				<h2 className="title">
					The impact of agricultural data starts with standardized, interoperable data
				</h2>
				<img className="image" src={background} />
				<div className="fake">
					<h2>
						The impact of agricultural data starts with standardized, interoperable data
					</h2>
					<img src={background} />
				</div>
			</div>
			<div className="down">
				<h3>What you can do with DataScribe
				</h3>
				<div className="cards">
					{renderCards()}
				</div>
			</div>
		</div>
	);
};

export default Home;
