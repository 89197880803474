import React from 'react';

const About = () => {
	return (
		<div>
			<p>
				A large number of agronomic questionnaires and surveys for
				household and trial data are developed by organizations in the
				agricultural sector. However, although large parts of such
				agronomic data collection forms may often be common, the forms
				are not typically shared and reused to save time and effort.
				Further, the variables, units, and question responses in these
				forms are not standard, making it difficult to compare,
				interrogate and aggregate data for analysis and learning.
			</p>
			<p>
				DataScribe is a free and open source software that helps users
				build and share Open Data Kit (ODK) forms focused on agronomic
				data collection. It enables users to easily pick
				standards-compliant variables and common choice lists, resulting
				in ODK forms that allow enumerators to easily record GPS
				coordinates, scan barcodes, add audio/video/images, multiple
				choice, free text and numeric responses and more. Entire or
				partial standardized forms can be reused by others – contributing
				to a growing corpus of standardized data that can be easily
				queried, combined and analyzed.
			</p>
			<p>
				DataScribe therefore facilitates sharing and reusing ODK forms
				to ease the digital data collection process. It also facilitates
				the creation of agronomic data that complies with community
				standards right at the collection point, making data across
				trials and projects comparable, interpretable, and easy to
				aggregate for analysis.
			</p>
			<p>Main features:</p>
			<ul>
				<li>
					Quickly build forms using the library of questions and
					choice lists tailored for the agricultural domain
				</li>
				<li>
					Use standard forms shared by CGIAR scientists
				</li>
				<li>
					Create your own templates from scratch or by uploading Excel files
				</li>
				<li>
					Ensure that your data will be reusable by annotating variables with community standards
				</li>
				<li>
					Link variables with standards units
				</li>
				<li>
					Collaborate with team members to develop and share forms
				</li>
				<li>
					Easily build in quantitative constraints to ensure high data quality
				</li>
				<li>
					Translate forms into multiple languages
				</li>
				<li>
					Test your forms online
				</li>
			</ul>
			<p>
				DataScribe has been developed by <a href="https://scio.systems/" target="_blank" rel="noreferrer">SCiO</a> working
				with the <a href="https://scalingagronomy.org/" target="_blank" rel="noreferrer">Excellence in Agronomy Initiative</a> of
				the <a href="https://cgiar.org/" target="_blank" rel="noreferrer">CGIAR</a>.
				For more information or training on how to get the most out of this tool, please contact <a href="https://scio.systems/" target="_blank" rel="noreferrer">SCiO</a>.
			</p>
		</div>
	);
};

export default About;
