import React from 'react';
import MenuBar from './components/MenuBar';

const App = () => {
	return (
		<MenuBar />
	);
};

export default App;
