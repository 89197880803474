import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import React, { useMemo, useState } from 'react';

const MultilingualEntriesTable = ({
	defaultLanguageCode,
	isEditable,
	mode,
	data,
	header,
	multipleLines,
	className,
	onDeleteItem,
	onAddItem,
	availableLanguages,
}) => {
	const [languageValue, setLanguageValue] = useState(defaultLanguageCode || '');
	const [textValue, setTextValue] = useState('');
	const [selectedLanguages, setSelectedLanguages] = useState(
		data ? data?.map(({ language }) => language?.code) || [] : []
	);

	const formattedLanguages = useMemo(
		() =>
			availableLanguages
				.filter(({ code }) => {
					if (selectedLanguages.includes(defaultLanguageCode) && code === defaultLanguageCode) {
						return false;
					}
					return true;
				})
				.filter(({ code }) => !selectedLanguages.includes(code)),
		[availableLanguages, defaultLanguageCode, selectedLanguages]
	);

	const onSubmit = (e) => {
		e.preventDefault();
		const lang = availableLanguages.find((l) => l.code === languageValue);
		onAddItem({ language: lang, value: textValue });
		setSelectedLanguages([...selectedLanguages, lang.code]);
		setLanguageValue('');
		setTextValue('');
	};

	const onDelete = (langValue) => {
		onDeleteItem(langValue);
		setSelectedLanguages(selectedLanguages.filter((s) => s !== langValue));
	};

	const shouldDisableInputs = useMemo(
		() => languageValue.length === 0 || selectedLanguages.includes(languageValue),
		[languageValue, selectedLanguages]
	);

	const footerTemplate = mode === 'edit' && (
		<form className="p-formgrid p-grid p-fluid" onSubmit={onSubmit}>
			<div className="p-col-3">
				<div className="p-field">
					<Dropdown
						optionLabel="name"
						optionValue="code"
						filter
						filterBy="name"
						value={languageValue}
						options={formattedLanguages}
						onChange={(e) => setLanguageValue(e.value)}
						placeholder="Select Language"
					/>
				</div>
			</div>
			<div className="p-col-7">
				<div className="p-field">
					{!multipleLines && (
						<InputText
							type="text"
							disabled={shouldDisableInputs}
							value={textValue}
							onChange={(e) => setTextValue(e.target.value)}
						/>
					)}
					{multipleLines && (
						<InputTextarea
							disabled={shouldDisableInputs}
							value={textValue}
							onChange={(e) => setTextValue(e.target.value)}
							autoResize
						/>
					)}
				</div>
			</div>
			<div className="p-col-2 p-text-right">
				<Button
					disabled={shouldDisableInputs}
					type="submit"
					label="Add"
					className="p-mr-2 p-mb-2"
				/>
			</div>
		</form>
	);

	return (
		<DataTable
			header={header}
			emptyMessage=""
			value={data}
			dataKey="language"
			className={classNames([className])}
			footer={footerTemplate}
		>
			<Column header="Language" field="name" body={({ language: { name } }) => name || ''} />
			<Column header="Value" field="value" />
			{mode === 'edit' && (
				<Column
					body={({ language }) => (
						<div className="p-text-right">
							{(isEditable) ? (
								<Button
									className="p-button-danger"
									icon="pi pi-trash"
									onClick={() => onDelete(language?.code)}
								/>
							) : (
								<>
									{((data.length > 0) && (language?.code !== data[0]?.language?.code)) && (
										<Button
											className="p-button-danger"
											icon="pi pi-trash"
											onClick={() => onDelete(language?.code)}
										/>
									)}
								</>
							)}
						</div>
					)}
				/>
			)}
		</DataTable>
	);
};

export default MultilingualEntriesTable;
