import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Message } from 'primereact/message';
import { MULTIPLE } from './Multiple';

const Style = ({
	enabled,
	isEditable,
	styleSelect,
	styleDropdownValues,
	requiredFields,
	updateOutput,
	sliderValues,
	valueStep,
	valueMax,
	questionId,
	multiple,
}) => {
	if (!enabled) {
		return null;
	}

	// If we have multiple on selection question then LikeRT is not an option.
	const dropdownOptions = styleDropdownValues.filter(
		(f) => !(multiple === MULTIPLE && ['LKR', 'QCK'].includes(f.code))
	);

	const tooltips = {
		numeric:
      'Style of the collection interface presented to the enumerator on the device. A Picker is a little textbox with plus and minus buttons to change the number.',
		calendar: 'Type of value to prompt for.',
		location: 'How to collect the information.',
		selection: 'Style of the collection interface presented to the enumerator on the device.',
	};

	const invalidValueStep =
    valueStep?.length === 0 || valueStep === undefined || valueStep > valueMax;

	return (
		<>
			<div className="p-col-12 p-md-6">
				<div className="custom-label">
					<Button
						label="Style"
						tooltip={tooltips[questionId] || ''}
						tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
					/>
				</div>
				<Dropdown
					id="style"
					disabled={!isEditable}
					value={styleSelect}
					onChange={(e) => updateOutput(e.target)}
					options={dropdownOptions}
					optionLabel="name"
					placeholder="Select"
				/>
				{requiredFields.includes('style') && styleSelect?.length === 0 && (
					<Message
						className="p-mt-2 p-text-left custom"
						severity="error"
						text="The style field is mandatory."
					/>
				)}
			</div>
			{sliderValues.includes(styleSelect?.code) && (
				<div className="p-col-12 p-md-12">
					<div className="p-inputgroup">
						<Button label="Step" />
						<InputNumber
							value={valueStep}
							id="step"
							disabled={!isEditable}
							inputId="step"
							onValueChange={(e) => updateOutput(e.target)}
							mode="decimal"
							min={0}
							max={valueMax || Number.MAX_SAFE_INTEGER}
							required={sliderValues.includes(styleSelect?.code)}
						/>
					</div>
					{sliderValues.includes(styleSelect?.code) && invalidValueStep && (
						<Message
							className="p-mt-2 p-text-left custom"
							severity="error"
							text="The step field is mandatory and must be less or equal to max."
						/>
					)}
				</div>
			)}
		</>
	);
};

export default Style;
