import React from 'react';
import './styles.css';

const About = () => {
	return (
		<div className="about">
			<h2>
				About
			</h2>
			<p>
				Many agronomic questionnaires and surveys for household and trial data are developed by organizations in the agricultural sector. However, although large parts of such agronomic data collection forms may often be common, the forms are not typically shared and reused to save time and effort. Further, the variables, units, and question responses in these forms are not standard, making it difficult to compare, interrogate and aggregate data for analysis and learning.
			</p>
			<p>
				DataScribe is a free and open-source software that helps users build and share Open Data Kit (ODK) forms focused on agronomic data collection. It enables users to easily pick standards-compliant variables and common choice lists, resulting in ODK forms that allow enumerators to easily record GPS coordinates, scan barcodes, add audio/ video/ images, multiple choice, free text, numeric responses and more. Entire or partial standardized forms can be reused by others – contributing to a growing corpus of standardized data that can be easily queried, combined and analyzed.
			</p>
			<p>
				DataScribe therefore enables the sharing and reuse of ODK forms to ease the building of questionnaires and the digital data collection process. It also facilitates the creation of agronomic data that complies with community standards right at the collection point, making data across trials and projects comparable, interoperable, interpretable, and easy to aggregate for analysis.
			</p>
			<p className="footer-text">
				DataScribe has been developed by <a href="https://www.scio.systems/" target="_blank" rel="noopener noreferrer">SCiO</a> working with the <a href="https://scalingagronomy.org/" target="_blank" rel="noopener noreferrer">Excellence in Agronomy Initiative</a> of <a href="https://cgiar.org/" target="_blank" rel="noopener noreferrer">CGIAR</a>.
			</p>
		</div>
	);
};

export default About;
