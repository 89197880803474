import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import MultilingualEntriesTable from '../MultilingualEntriesTable';

const Constraint = ({
	enabled,
	isEditable,
	valueConstraint,
	valueConstraintMessage,
	updateOutput,
	availableLanguages,
	defaultLanguage,
}) => {
	if (!enabled) {
		return null;
	}

	const handleAddItem = (item) => {
		updateOutput({
			id: 'constraint_message',
			value: valueConstraintMessage ? valueConstraintMessage.concat([item]) : [item],
		});
	};

	const handleDeleteItem = (langValue) => {
		updateOutput({
			id: 'constraint_message',
			value: valueConstraintMessage
				? valueConstraintMessage.filter((vl) => vl.language.code !== langValue)
				: [],
		});
	};

	return (
		<>
			<div className="p-col-12">
				<div className="p-inputgroup">
					<Tooltip target=".constrain-tooltip">
						Use formulas to define the spectrum of meaningful answers for your question.
						<br /> (click for more information)
					</Tooltip>
					<Button
						className="constrain-tooltip"
						label="Constraint"
						onClick={() =>
							window.open(
								'https://docs.getodk.org/form-logic/#validating-and-restricting-responses',
								'_blank',
								'noopener,noreferrer'
							)
						}
					/>
					<InputText
						value={valueConstraint}
						disabled={!isEditable}
						id="constraint"
						placeholder=""
						onChange={(e) => updateOutput(e.target)}
					/>
				</div>
			</div>
			<div className="p-col-12">
				<div>
					<MultilingualEntriesTable
						defaultLanguageCode={defaultLanguage?.code}
						isEditable={isEditable}
						className="custom-multilingual-entry"
						mode="edit"
						data={valueConstraintMessage}
						onAddItem={handleAddItem}
						onDeleteItem={handleDeleteItem}
						header="Constraint Message"
						availableLanguages={availableLanguages}
					/>
				</div>
			</div>
		</>
	);
};

export default Constraint;
