import { BlockUI } from 'primereact/blockui';
import { Fieldset } from 'primereact/fieldset';
import { PickList } from 'primereact/picklist';
import { Toolbar } from 'primereact/toolbar';
import React from 'react';

const MatchingQueries = ({
	enabled,
	rightMatchingToolbarTemplate,
	blockedMatchingQueries,
	source,
	target,
	setSource,
	setTarget,
}) => {
	if (!enabled) {
		return null;
	}

	const onChange = (event) => {
		setSource(event.source);
		setTarget(event.target);
	};

	const itemTemplateQuery = (item) => {
		let questionText = '';
		let questionTextEn = '';

		if (item.label === item.label_en) {
			questionText = item.label;
		} else {
			questionText = item.label;
			questionTextEn = `( ${item.label_en} )`;
		}

		return (
			<div className="product-item">
				<div className="product-list-detail">
					<h4 className="mb-2">{`${questionText} `}</h4>
					<h5>{`${questionTextEn}`}</h5>
				</div>
			</div>
		);
	};

	return (
		<Fieldset legend="Matching Questions" toggleable className="p-mt-4">
			<Toolbar className="p-mb-4" right={rightMatchingToolbarTemplate} />
			<div>
				<BlockUI blocked={blockedMatchingQueries}>
					<PickList
						source={source}
						target={target}
						itemTemplate={itemTemplateQuery}
						sourceHeader="Select questions that matches yours"
						targetHeader="Selected"
						sourceStyle={{ height: '342px' }}
						targetStyle={{ height: '342px' }}
						onChange={onChange}
					/>
				</BlockUI>
			</div>
		</Fieldset>
	);
};

export default MatchingQueries;
