import { Checkbox } from 'primereact/checkbox';
import React, { useEffect } from 'react';

const ReadOnly = ({ enabled, isEditable, readOnly, setReadOnly, required, setRequired, type }) => {
	if (!enabled) {
		return null;
	}

	// eslint-disable-next-line react-hooks/rules-of-hooks
	useEffect(
		() => {
			if (readOnly && required) {
				setRequired(false);
			}
		}, [readOnly]
	);

	// eslint-disable-next-line react-hooks/rules-of-hooks
	useEffect(
		() => {
			if (readOnly && required) {
				setReadOnly(false);
			}
		}, [required]
	);

	return (
		<div className="p-col-12 p-md-6">
			{type === 'text'
				? (
					<div className="p-col-12">
						<Checkbox
							disabled={!isEditable}
							inputId="readonly"
							checked={readOnly}
							onChange={(e) => setReadOnly(e.checked)}
						/>
						<label htmlFor="readonly" style={{ paddingLeft: '10px' }}>
							{readOnly ? 'Read-only' : 'Read-only'}
						</label>
					</div>
				)
				: null}
			<div className="p-col-12">
				<Checkbox
					disabled={!isEditable}
					inputId="required"
					checked={required}
					onChange={(e) => setRequired(e.checked)}
				/>
				<label htmlFor="required" style={{ paddingLeft: '10px' }}>
					{required ? 'Required' : 'Required'}
				</label>
			</div>
		</div>
	);
};

export default ReadOnly;
