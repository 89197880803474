import { nanoid } from 'nanoid';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { useState, useCallback } from 'react';
import { getBlock } from '../../services/blocks';

const ImportBlockModal = ({ toast, visible, setVisible, libraryBlocks, onImportBlock }) => {
	const [loading, setLoading] = useState(false);
	const [selectedBlock, setSelectedBlock] = useState(null);

	const resetAndHide = () => {
		setSelectedBlock(null);
		setLoading(false);
		setVisible(false);
	};

	const regenerateKeys = useCallback(
		(treeNodes) => {
			for (let i = 0; i < treeNodes.length; i += 1) {
				const newKey = nanoid();
				// eslint-disable-next-line no-param-reassign
				treeNodes[i].key = newKey;
				// eslint-disable-next-line no-param-reassign
				treeNodes[i].data.key = newKey;
				if (treeNodes[i].children && treeNodes[i].children.length > 0) {
					regenerateKeys(treeNodes[i].children);
				}
			}
			return treeNodes;
		},
		[]
	);

	const onSubmit = async () => {
		if (!selectedBlock) return;
		setLoading(true);
		try {
			const { data } = await getBlock(selectedBlock);
			data.body.nodes = regenerateKeys(data.body.nodes);
			onImportBlock(data.body);
			toast.current.show({
				severity: 'success',
				summary: 'Block was imported successfully.',
				life: 3000,
			});
			resetAndHide();
		} catch (error) {
			toast.current.show({
				severity: 'error',
				summary: 'Something went wrong while importing the selected block.',
				life: 3000,
			});
		}
	};

	return (
		<Dialog
			header="Import block from your library"
			visible={visible}
			maximizable
			modal
			closable
			style={{ minWidth: '600px' }}
			footer={null}
			onHide={() => resetAndHide()}
		>
			<div className="p-grid p-formgrid p-pt-1">
				<div className="p-col-12 p-field p-fluid">
					<Dropdown
						optionLabel="name"
						optionValue="id"
						filter
						filterBy="name"
						value={selectedBlock}
						options={libraryBlocks}
						onChange={(e) => setSelectedBlock(e.value)}
						placeholder="Select a block from your library"
					/>
				</div>
				<div className="p-col-12">
					<Button
						disabled={selectedBlock === null}
						onClick={onSubmit}
						label="Import block"
						icon="fa fa-duotone fa-plus"
						loading={loading}
					/>
				</div>
			</div>
		</Dialog>
	);
};

export default ImportBlockModal;
