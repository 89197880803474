import apiClient from '../utilities/api-client';

export const getBlocks = async () => apiClient.get(`/blocks`);

export const getMultipleBlocks = async (ids) => apiClient.get(`/blocks/multiple?ids=${ids}`);

export const getBlock = async (id) => apiClient.get(`/blocks/${id}`);

export const createBlock = async (body) => apiClient.post('/blocks', body);

export const updateBlock = async (id, body) => apiClient.put(`/blocks/${id}`, body);

export const deleteBlock = async (id) => apiClient.remove(`/blocks/${id}`);

export const publishBlock = async (id) => apiClient.post(`/blocks/${id}/publish`);

export const searchBlocks = async (term) => apiClient.post(`/blocks/search`, { term });

export const importBlock = async (uuid) => apiClient.post(`/blocks/import`, { external_id: uuid });
