import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import 'primereact/resources/primereact.min.css';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Redirect, useLocation } from 'react-router-dom';
import Footer from '../../components/Footer';
import Logo from '../../components/Logo';
import { authProviders } from '../../data/auth-providers';
import { login, verify } from '../../services/auth';
import { UserContext } from '../../store';
import { Home, ContactUs, About, MenuBar } from './components';
import './styles.css';

const Login = () => {
	const { t } = useTranslation();
	const toast = useRef();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const { isLoggedIn, setUser, resetData } = useContext(UserContext);
	const [isLoading, setIsLoading] = useState(null);
	const [authProvider, setAuthProvider] = useState(authProviders[0]);
	const [currentPage, setCurrentPage] = useState('home');
	const { search } = useLocation();

	useEffect(() => {
		resetData();
		const accessToken = new URLSearchParams(search).get('access_token');
		if (accessToken) {
			setIsLoading(true);
			verify(accessToken)
				.then(({ data }) => {
					console.log(data);
					setUser({
						...data.data,
						access_token: accessToken,
						isLoggedIn: true,
					});
				})
				.catch((error) => {
					// eslint-disable-next-line
					console.error(error);
				});
			setIsLoading(false);
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const authWithLocal = async () => {
		try {
			const { data: responseData } = await login(email, password);
			setUser({
				...responseData.data.user,
				access_token: responseData.data.access_token,
				isLoggedIn: true,
			});
		} catch (e) {
			let error = 'Something went wrong';
			if (e.response) {
				const statusCode = e.response && e.response.status;
				error = statusCode === 422
					? e.response.data.errors[Object.keys(e.response.data.errors)[0]][0]
					: e.response.data.error;
			}
			toast.current.show({
				severity: 'error',
				summary: 'Oops!',
				detail: error,
			});
		}
	};

	const authWithORCID = () => {
		window.location.href = process.env.REACT_APP_ORCID_REDIRECT_URL;
	};

	const authWithAuth0 = () => {
		window.location.href = process.env.REACT_APP_AUTH0_REDIRECT_URL;
	};

	// eslint-disable-next-line consistent-return
	const loginHandler = (e) => {
		e.preventDefault();
		setIsLoading(true);
		switch (authProvider) {
		case 'local':
			authWithLocal();
			break;
		case 'orcid':
			authWithORCID();
			break;
		case 'auth0':
			authWithAuth0();
			break;
		default:
		}
		setIsLoading(false);
	};

	if (isLoggedIn) {
		return <Redirect to="/" />;
	}

	const renderPage = () => {
		switch (currentPage) {
		case 'home': return <Home />;
		case 'about': return <About />;
		case 'contact-us': return <ContactUs />;
		case 'login': return (
			<>
				<Toast ref={toast} position="top-right" />
				<div className="p-d-flex p-jc-center p-ai-center p-flex-column" style={{ height: '85vh' }}>
					<div className="p-d-flex p-jc-center p-ai-center p-flex-column">
						<Logo variant="black" />
						<p style={{ textAlign: 'center', fontWeight: '500', fontSize: '16px' }}>
							Powerful agricultural data starts at form development
							<br />
							Use DataScribe to build mobile data collection forms aligned with community standards.
						</p>
						<div
							className="p-d-flex p-flex-column p-p-6 p-shadow-5 rounded"
							style={{ width: '500px', maxWidth: '100%' }}
						>
							<h3 className="p-d-block p-text-center p-mb-5">{t('LOGIN_WITH')}</h3>
							<form onSubmit={loginHandler}>
								<div className="p-grid p-fluid p-formgrid p-justify-center p-mb-6">
									<div className="p-col-12 p-md-8">
										<Dropdown
											value={authProvider}
											options={authProviders}
											onChange={(e) => setAuthProvider(e.value)}
										/>
									</div>
								</div>
								<div className="p-grid p-fluid p-formgrid p-justify-center">
									{authProvider === 'local' && (
										<div className="p-col-12 p-md-8">
											<InputText
												id="email"
												value={email}
												onChange={(e) => setEmail(e.target.value)}
												placeholder="Enter your email"
												autoComplete="on"
											/>
											<Password
												className="p-mt-3"
												id="password"
												value={password}
												onChange={(e) => setPassword(e.target.value)}
												placeholder="Enter your password"
												feedback={false}
												autoComplete="off"
												toggleMask
											/>
										</div>
									)}
								</div>
								<div className="p-grid p-formgrid p-justify-center p-mt-2">
									<div className="p-col-12 p-md-8 p-fluid p-text-center">
										<Button
											label={t('LOGIN_BUTTON_TEXT')}
											className="p-d-inline p-mt-4"
											loading={isLoading}
											type="submit"
											onClick={loginHandler}
										/>
									</div>
									<p
										className="p-col-12 p-md-12 p-text-center p-mt-6"
										style={{ visibility: authProvider === 'local' ? 'visible' : 'hidden' }}
									>
										<NavLink to="/register">{t('SIGN_UP_LINK_TEXT')}</NavLink>
									</p>
								</div>
							</form>
						</div>
					</div>
				</div>
			</>
		);
		default: return <Home />;
		}
	};

	return (
		<div className="login-pages">
			<Toast ref={toast} position="top-right" />
			<MenuBar setCurrentPage={setCurrentPage} />
			{renderPage()}
			<Footer />
		</div>
	);
};

export default Login;
