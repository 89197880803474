import { Button } from 'primereact/button';
import { SpeedDial } from 'primereact/speeddial';
import { Tooltip } from 'primereact/tooltip';
import { useState } from 'react';

const QuestionButtons = ({ items, onImportClick }) => {
	const [expanded, setExpanded] = useState(false);

	return (
		<div
			style={{ position: 'fixed', bottom: '80px', right: 0, zIndex: expanded ? 9999999999 : 1000 }}
		>
			<div
				style={{ position: 'relative' }}
				className="speeddial-linear-demo speedial-square-button"
			>
				<Tooltip
					target=".speeddial-linear-demo .speeddial-left .p-speeddial-action"
					position="top"
				/>
				<Tooltip target=".p-speeddial-button" position="top">
					Add a Question: choose the type depending on the kind of response you expect (e.g.,
					numeric, text, geolocation etc.)
				</Tooltip>
				<SpeedDial
					onShow={() => setExpanded(true)}
					onHide={() => setExpanded(false)}
					rotateAnimation={false}
					model={items}
					direction="left"
					className="speeddial-left"
				/>
			</div>
			<div style={{ position: 'relative' }} className="p-mt-2">
				<Button
					className="p-button-success import-from-library-button"
					style={{ fontSize: '1.3rem' }}
					onClick={onImportClick}
					icon="fa-duotone fa-file-import"
					tooltip="Import from library"
					tooltipOptions={{ position: 'left', mouseTrack: true, mouseTrackTop: 15 }}
				/>
			</div>
		</div>
	);
};

export default QuestionButtons;
