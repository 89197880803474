import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { useState } from 'react';
import { importVocabulary, searchVocabularies } from '../../services/vocabularies';

const SearchVocabulariesModal = ({ toast, visible, setVisible, onVocabulariesRefresh }) => {
	const [searchQuery, setSearchQuery] = useState('');
	const [loading, setLoading] = useState(false);
	const [importLoading, setImportLoading] = useState(false);
	const [vocabularies, setVocabularies] = useState([]);

	const resetAndHide = () => {
		setVisible(false);
		setVocabularies([]);
		setSearchQuery('');
	};

	const getLabel = (choice) => choice?.required?.filter((c) => c.header === 'label')?.pop()?.value;

	const searchForGlobalVocabularies = async () => {
		setLoading(true);
		try {
			const { data } = await searchVocabularies(searchQuery);
			setVocabularies(data || []);
		} catch (error) {
			setVocabularies([]);
			toast.current.show({
				life: 5000,
				severity: 'error',
				summary: 'Error',
				detail: 'Failed to search for choice lists. Please try again.',
			});
		}
		setLoading(false);
	};

	const importGlobalVocabulary = async (uuid) => {
		setImportLoading(true);
		try {
			await importVocabulary(uuid);
			onVocabulariesRefresh();
			toast.current.show({
				severity: 'success',
				summary: 'Imported choice list',
				detail: 'The choice list was imported successfully.',
			});
			if (vocabularies.length === 1) {
				resetAndHide();
			}
		} catch (error) {
			toast.current.show({
				life: 5000,
				severity: 'error',
				summary: 'Error',
				detail:
          'Failed to import choice list. Make sure that the imported choice list does not already exist.',
			});
		}
		setImportLoading(false);
	};

	return (
		<Dialog
			header="Search Global Choice Lists"
			visible={visible}
			maximizable
			modal
			closable
			style={{ minWidth: '600px' }}
			footer={null}
			onHide={() => resetAndHide()}
		>
			<div className="p-grid p-pt-1">
				<div className="p-col-12">
					<form
						className="p-fluid p-pb-2 p-grid p-formgrid"
						onSubmit={(e) => {
							e.preventDefault();
							searchForGlobalVocabularies();
						}}
					>
						<div className="p-col-9">
							<InputText
								placeholder="Enter search details..."
								value={searchQuery}
								onChange={(e) => setSearchQuery(e.target.value)}
							/>
						</div>
						<div className="p-col-3">
							<Button
								icon="fa-duotone fa-magnifying-glass"
								className="p-button-info"
								label="Search"
								loading={loading || importLoading}
								disabled={!searchQuery || loading}
								onClick={() => searchForGlobalVocabularies()}
							/>
						</div>
					</form>
					<div className="p-grid">
						<div className="p-col-12">
							{!loading &&
								vocabularies.length > 0 &&
								vocabularies.map(({ uuid, listname, description, choices }) => (
									<div className="p-mt-3" key={uuid}>
										<Panel collapsed header={listname} toggleable>
											<div className="p-mb-2">
												<strong>Description</strong>
											</div>
											{ description || 'N/A'}
											{choices && choices.length > 0 && (
												<div>
													<hr />
													<div className="p-mt-2">
														<strong>Choices</strong>
														<ul className="p-mt-2 p-mb-0">
															{choices.map((c) => (
																<li key={c.id}>{getLabel(c)}</li>
															))}
														</ul>
													</div>
												</div>
											)}
											<div className="p-text-right">
												<Button
													icon="fa-duotone fa-file-import"
													label="Import"
													loading={importLoading}
													onClick={() => importGlobalVocabulary(uuid)}
												/>
											</div>
										</Panel>
									</div>
								))}
						</div>
					</div>
				</div>
			</div>
		</Dialog>
	);
};

export default SearchVocabulariesModal;
