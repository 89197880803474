import { nanoid } from 'nanoid';
import { BlockUI } from 'primereact/blockui';
import { Button } from 'primereact/button';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { ContextMenu } from 'primereact/contextmenu';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';
import { Tree } from 'primereact/tree';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import getQuestionConfig from '../../../../data/question-configuration';
import { QUESTION_MODE_NEW, QUESTION_MODE_UPDATE } from '../../../../data/question-modes';
import { getBlocks } from '../../../../services/blocks';
import { getLanguages } from '../../../../services/languages';
import { getOntologies } from '../../../../services/ontologies';
import {
	createQuestionnaire,
	downloadCarobScript,
	downloadQuestionnaireAsXLSForm,
	updateQuestionnaire,
	getPreviewQuestionnaireLink,
} from '../../../../services/questionnaires';
import { getVocabularies } from '../../../../services/vocabularies';
import QuestionButtons from '../../../../components/buttons/QuestionButtons';
import CreateBlockModal from '../../../../components/dialogs/CreateBlockModal';
import ImportBlockModal from '../../../../components/dialogs/ImportBlockModal';
import PreviewQuestionnaireDialog from '../../../../components/dialogs/PreviewQuestionnaireDialog';
import Question from '../../../../components/forms/Question';
import VocabulariesTable from '../../../../components/forms/Vocabularies/VocabulariesTable';
import VocabularyModal from '../../../../components/forms/Vocabularies/VocabularyModal';
import QuestionnaireSettings from './QuestionnaireSettings';

const QuestionnaireForm = ({ data = undefined }) => {
	const [visibleSave, setVisibleSave] = useState(false);
	const [viewOnlyMode, setViewOnlyMode] = useState(data !== undefined);
	const toast = useRef(null);

	const history = useHistory();

	// Block UI
	const [blockedPanel, setBlockedPanel] = useState(false);

	// Preview Settings
	const [previewEnabled, setPreviewEnabled] = useState(true);
	const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
	const [lastPreviewUrl, setLastPreviewUrl] = useState(null);

	// Settings
	const [ID, setID] = useState('');
	const [internalID, setInternalID] = useState(null);
	const [title, setTitle] = useState('');
	const [version, setVersion] = useState('');

	// Questions
	const [questionsConfiguration] = useState(getQuestionConfig());
	const [question, setQuestion] = useState(null);
	const [showQuestion, setShowQuestion] = useState(false);
	const [questionData, setQuestionData] = useState(null);
	const [questionMode, setQuestionMode] = useState(null);

	// Blocks
	const [libraryBlocks, setLibraryBlocks] = useState([]);
	const [createBlockModalVisible, setCreateBlockModalVisible] = useState(false);
	const [importBlockModalVisible, setImportBlockModalVisible] = useState(false);
	const [blockData, setBlockData] = useState(null);

	// Questionnaire Vocabularies
	const [vocs, setVocs] = useState([]);
	const [vocModalVisible, setVocModalVisible] = useState(false);
	const [selectedVocabulary, setSelectedVocabulary] = useState(null);
	const [vocToLoad, setVocToLoad] = useState(null);
	const [vocOptions, setVocOptions] = useState([]);

	// Group Selections
	const [nodes, setNodes] = useState([]);
	const [expandedKeys, setExpandedKeys] = useState({});
	const [selectedGroup, setSelectedGroup] = useState(null);
	const [selectedLabel, setSelectedLabel] = useState([]);
	const [selectedLabelTag, setSelectedLabelTag] = useState(true);
	const [selectedNodeKey, setSelectedNodeKey] = useState(null);
	const [languageValues, setLanguageValues] = useState([]);
	const [languageValue, setLanguageValue] = useState(null);
	const [options, setOptions] = useState({
		start: false,
		end: false,
		today: false,
		userid: false,
		email: false,
		phonenumber: false,
	});

	const [hasUnsavedWork, setHasUnsavedWork] = useState(false);
	const [ontologies, setOntologies] = useState(null);
	const cm = useRef(null);

	const menu = [
		{
			label: 'Delete',
			icon: 'fa-duotone fa-eraser',
			command: () => deleteNode(nodes[selectedNodeKey]),
		},
	];

	const items = [
		{
			label: 'URL',
			icon: 'fa-duotone fa-link',
			command: () => {
				setQuestion(questionsConfiguration.url);
				setQuestionData({});
				setQuestionMode(QUESTION_MODE_NEW);
				setShowQuestion(true);
			},
		},
		{
			label: 'Calculation',
			icon: 'fa-duotone fa-calculator-simple',
			command: () => {
				setQuestion(questionsConfiguration.calculation);
				setQuestionData({});
				setQuestionMode(QUESTION_MODE_NEW);
				setShowQuestion(true);
			},
		},
		{
			label: 'Text',
			icon: 'fa-duotone fa-input-text',
			command: () => {
				setQuestion(questionsConfiguration.text);
				setQuestionData({});
				setQuestionMode(QUESTION_MODE_NEW);
				setShowQuestion(true);
			},
		},
		{
			label: 'Barcode',
			icon: 'fa-duotone fa-barcode-read',
			command: () => {
				setQuestion(questionsConfiguration.barcode);
				setQuestionData({});
				setQuestionMode(QUESTION_MODE_NEW);
				setShowQuestion(true);
			},
		},
		{
			label: 'Media',
			icon: 'fa-duotone fa-photo-film-music',
			command: () => {
				setQuestion(questionsConfiguration.media);
				setQuestionData({});
				setQuestionMode(QUESTION_MODE_NEW);
				setShowQuestion(true);
			},
		},
		{
			label: 'Selection | Choice list has to be decided before creating a selection type question.',
			icon: 'fa-duotone fa-square-check',
			command: () => {
				setQuestion(questionsConfiguration.selection);
				setQuestionData({});
				setQuestionMode(QUESTION_MODE_NEW);
				setShowQuestion(true);
			},
		},
		{
			label: 'Location',
			icon: 'fa-duotone fa-location-dot',
			command: () => {
				setQuestion(questionsConfiguration.location);
				setQuestionData({});
				setQuestionMode(QUESTION_MODE_NEW);
				setShowQuestion(true);
			},
		},
		{
			label: 'Date/Time',
			icon: 'fa-duotone fa-calendar',
			command: () => {
				setQuestion(questionsConfiguration.calendar);
				setQuestionData({});
				setQuestionMode(QUESTION_MODE_NEW);
				setShowQuestion(true);
			},
		},
		{
			label: 'Numeric',
			icon: 'fa-duotone fa-input-numeric',
			command: () => {
				setQuestion(questionsConfiguration.numeric);
				setQuestionData({});
				setQuestionMode(QUESTION_MODE_NEW);
				setShowQuestion(true);
			},
		},
		{
			label: 'Group',
			icon: 'fa-duotone fa-object-group green-group',
			command: () => {
				setQuestion(questionsConfiguration.group);
				setQuestionData({});
				setQuestionMode(QUESTION_MODE_NEW);
				setShowQuestion(true);
			},
		},
	];

	useEffect(() => {
		setBlockedPanel(true);
		if (data && data.body) {
			// init our state.
			setID(data.body.metadata.ID);
			setInternalID(data.id);
			setTitle(data.body.metadata.title);
			setVersion(data.body.metadata.version);
			setLanguageValue(data.body.metadata.language);
			setNodes(data.body.nodes);
			if (data.body?.vocabularies) {
				// Create uuid if it does not exist as we use it to check for vocs.
				setVocs(
					data.body?.vocabularies?.map((v) => {
						if (v.uuid) return v;
						return { ...v, uuid: uuidv4(), local: true };
					})
				);
			}
			if (data.body.metadata.options) {
				setOptions(data.body.metadata.options);
			}

			if (data.role !== 'viewer') {
				setViewOnlyMode(false);
			}
		}

		getLanguages()
			.then(({ data: d }) => {
				setLanguageValues(d || []);
				setBlockedPanel(false);
			})
			.catch(console.error);

		getVocabularies()
			.then(({ data: d }) => {
				setVocOptions(d || []);
				setBlockedPanel(false);
			})
			.catch(console.error);

		getBlocks()
			.then(({ data: d }) => {
				setLibraryBlocks(d || []);
				setBlockedPanel(false);
			})
			.catch(console.error);

		getOntologies()
			.then(({ data: d }) => setOntologies(d))
			.catch(console.error);
  }, []); // eslint-disable-line

	const traverseTree = useCallback((treeNodes, cb) => {
		for (let i = 0; i < treeNodes.length; i += 1) {
			cb(treeNodes[i]);
			if (treeNodes[i].children && treeNodes[i].children.length > 0) {
				traverseTree(treeNodes[i].children, cb);
			}
		}
	}, []);

	const findVocUsages = useCallback(
		(allNodes) => {
			const vocQuestions = {};
			for (let i = 0; i < vocs.length; i += 1) {
				const id = vocs[i]?.body?.id || vocs[i]?.id;
				vocQuestions[id] = [];
			}
			const availableVocIds = Object.keys(vocQuestions);

			traverseTree(allNodes, (n) => {
				if (n.data?.id === 'selection') {
					if (availableVocIds.includes(n.data?.vocabulary)) {
						vocQuestions[n.data?.vocabulary].push({ label: n.data?.label });
					}
				}
			});

			setVocs((oldVocs) => [
				...oldVocs.map((ov) => ({ ...ov, usedInQuestions: vocQuestions[ov?.body?.id || ov?.id] })),
			]);
		},
		[traverseTree, vocs]
	);

	const updateOption = (optName, checked) => {
		setOptions({ ...options, [optName]: checked });
		setHasUnsavedWork(true);
	};

	const template = (opts) => {
		const toggleIcon = opts.collapsed ? 'fa-duotone fa-chevron-down' : 'fa-duotone fa-chevron-up';
		const className = `${opts.className} justify-content-start`;
		const titleClassName = `${opts.titleClassName} pl-1`;

		return (
			<div className={className}>
				<Button className={opts.togglerClassName} onClick={opts.onTogglerClick}>
					<span className={toggleIcon} />
					<Ripple />
				</Button>
				<span className={titleClassName}>{opts.props.header}</span>
			</div>
		);
	};

	const extractLabel = (qData) => {
		if (qData.id === 'calculation') return `calculation (${qData.variable})`;
		return `${qData.label[0].value} (${qData.name})`;
	};

	const printOutput = (qData) => {
		setQuestionData(null);
		setShowQuestion(false);

		let icon = '';
		let group = false;
		let uitype = '';

		if (qData) {
			if (qData.id === 'media') {
				icon = 'fa-duotone fa-photo-film-music fa-xl';
				uitype = 'Media';
			} else if (qData.id === 'barcode') {
				icon = 'fa-duotone fa-barcode-read fa-xl';
				uitype = 'Barcode';
			} else if (qData.id === 'calendar') {
				icon = 'fa-duotone fa-calendar fa-xl';
				uitype = 'Calendar';
			} else if (qData.id === 'location') {
				icon = 'fa-duotone fa-location-dot fa-xl';
				uitype = 'Location';
			} else if (qData.id === 'numeric') {
				icon = 'fa-duotone fa-input-numeric fa-xl';
				uitype = 'Numeric';
			} else if (qData.id === 'text') {
				icon = 'fa-duotone fa-input-text fa-xl';
				uitype = 'Text';
			} else if (qData.id === 'selection') {
				icon = 'fa-duotone fa-square-check fa-xl';
				uitype = 'Selection';
			} else if (qData.id === 'group') {
				group = true;
				icon = 'fa-duotone fa-object-group fa-xl';
				uitype = 'Group';
			} else if (qData.id === 'calculation') {
				icon = 'fa-duotone fa-calculator-simple fa-xl';
				uitype = 'Calculation';
			} else if (qData.id === 'url') {
				icon = 'fa-duotone fa-link fa-xl';
				uitype = 'url';
			}

			// eslint-disable-next-line no-param-reassign
			qData.uitype = uitype;

			if (qData.mode === QUESTION_MODE_UPDATE) {
				// eslint-disable-next-line no-plusplus
				for (let i = 0; i < nodes.length; i++) {
					if (nodes[i].key === qData.key) {
						nodes[i].label = extractLabel(qData);
						nodes[i].data = qData;
						break;
					}

					if (nodes[i]?.children?.length > 0) {
						updateNodeTree(nodes[i], qData.key, qData);
					}
				}

				const tempNodes = JSON.parse(JSON.stringify(nodes));
				setNodes(tempNodes);
				findVocUsages(tempNodes);
			} else if (qData.mode === QUESTION_MODE_NEW) {
				const generatedKey = nanoid();
				if (nodes.length === 0) {
					const firstNode = {
						key: generatedKey,
						label: extractLabel(qData),
						icon,
						data: { ...qData, key: generatedKey },
					};

					if (group) {
						firstNode.children = [];
					}

					const tempNodes = [];
					tempNodes.push(firstNode);
					setNodes([...tempNodes]);
					findVocUsages(tempNodes);
				} else if (selectedGroup) {
					if (selectedGroup?.node?.data?.id === 'group') {
						const leafNode = selectedGroup.node;
						// eslint-disable-next-line no-param-reassign
						qData.key = generatedKey;

						const leaf = {
							key: generatedKey,
							label: extractLabel(qData),
							icon,
							data: qData,
						};

						if (group) {
							leaf.children = [];
						}

						// NOTE: The following works because selectedGroup is by reference
						leafNode.children.push(leaf);
					} else {
						const key = generatedKey;
						// eslint-disable-next-line no-param-reassign
						qData.key = key;
						const leaf = {
							key,
							label: extractLabel(qData),
							icon,
							data: qData,
						};

						if (group) {
							leaf.children = [];
						}

						const tempNodes = nodes;
						tempNodes.push(leaf);
						setNodes([...tempNodes]);
						findVocUsages(tempNodes);
					}
				} else {
					const newNode = {
						key: generatedKey,
						label: extractLabel(qData),
						icon,
						data: { ...qData, key: generatedKey },
					};

					if (group) {
						newNode.children = [];
					}

					const tempNodes = nodes;
					tempNodes.push(newNode);
					setNodes([...tempNodes]);
					findVocUsages(tempNodes);
				}
			}
		}
	};

	const deleteNodeFromTree = (node, nodeId) => {
		if (node.children && node.children.length) {
			// eslint-disable-next-line no-plusplus
			for (let i = 0; i < node.children.length; i++) {
				const filtered = node.children.filter((f) => f.key === nodeId);
				if (filtered && filtered.length > 0) {
					// eslint-disable-next-line no-param-reassign
					node.children = node.children.filter((f) => f.key !== nodeId);
					return;
				}
				deleteNodeFromTree(node.children[i], nodeId);
			}
		}
		setHasUnsavedWork(true);
	};

	// eslint-disable-next-line consistent-return
	const updateNodeTree = (node, nodeId, d) => {
		if (node.children) {
			// eslint-disable-next-line no-plusplus
			for (let i = 0; i < node.children.length; i++) {
				if (node.children[i].key === nodeId) {
					// eslint-disable-next-line no-param-reassign
					node.children[i].data = d;
					// eslint-disable-next-line no-param-reassign
					node.children[i].label = `${d.label[0].value} (${d.name})`;
					return;
				}
				updateNodeTree(node.children[i], nodeId, d);
			}
		}
		setHasUnsavedWork(true);
	};

	const getLabelByLanguage = ({ node }, langCode = 'eng') => {
		const langCodeLabel = node?.data?.label?.find(
			({ language }) => language?.code === langCode
		)?.value;
		const firstAvailableLabel = node?.data?.label[0]?.value;

		return langCodeLabel || firstAvailableLabel || 'N/A';
	};

	const onNodeSelect = (node) => {
		// If any buttons like edit or delete were clicked do not select the question.
		if (node?.originalEvent?.target?.className?.toLowerCase()?.indexOf('button') !== -1) {
			return;
		}

		setSelectedNodeKey(node.key);
		setSelectedGroup(node);
		setSelectedLabelTag(false);
		setSelectedLabel([getLabelByLanguage(node)].filter(Boolean));
	};

	const onNodeUnselect = (node) => {
		toast.current.show({
			severity: 'success',
			summary: 'Question Unselected:',
			detail: node.node.data.label,
			life: 3000,
		});
		setSelectedGroup(null);
		setSelectedLabel([]);
		setSelectedLabelTag(true);
	};

	const rightContents = !viewOnlyMode && (
		<QuestionButtons onImportClick={() => setImportBlockModalVisible(true)} items={items} />
	);

	const expandAll = () => {
		// eslint-disable-next-line no-underscore-dangle
		const _expandedKeys = {};
		for (const node of nodes) {
			expandNode(node, _expandedKeys);
		}

		setExpandedKeys(_expandedKeys);
	};
	const collapseAll = () => {
		setExpandedKeys({});
	};
	const expandNode = (node, _expandedKeys) => {
		if (node.children && node.children.length) {
			// eslint-disable-next-line no-param-reassign
			_expandedKeys[node.key] = true;

			for (const child of node.children) {
				expandNode(child, _expandedKeys);
			}
		}
	};

	const leftContents = () => {
		let flag = false;
		if (nodes.find((item) => item.children)) {
			flag = true;
		}
		return (
			nodes?.length > 0
				? (
					<div style={{ display: 'flex', flexDirection: 'row', gap: '12px', alignItems: 'center' }}>
						<Tooltip target=".current-selection" position="top" content="Current Selection" />
						Current Selection: &nbsp;
						<span hidden={selectedLabelTag}>
							<Tag value={selectedLabel} severity="success" />
						</span>
						{
							flag
								? (
									<div className="mb-4" style={{ display: 'flex', flexDirection: 'row', gap: '12px', alignItems: 'center' }}>
										<Button type="button" icon="fa-regular fa-plus" label="Expand All" onClick={expandAll} className="mr-2" />
										<Button type="button" icon="fa-regular fa-minus" label="Collapse All" onClick={collapseAll} />
									</div>
								)
								: null
						}
					</div>
				) : (
					<div>You can add a question by pressing the add button.</div>
				)
		);
	};

	const updateOrCreate = () => {
		if (nodes.length === 0) {
			toast.current.show({
				severity: 'error',
				summary: `You can't have an empty questionnaire.`,
				detail: '',
				life: 3000,
			});
			return;
		}

		const metadata = {
			title,
			ID,
			version,
			language: languageValue,
			options,
		};

		if (data === undefined) {
			// we create a questionnaire
			setBlockedPanel(true);
			createQuestionnaire({ nodes, metadata, vocabularies: vocs })
				.then(({ data: d }) => {
					setBlockedPanel(false);
					toast.current.show({
						severity: 'info',
						summary: 'Confirmed',
						detail: 'Success',
						life: 3000,
					});
					setHasUnsavedWork(false);
					history.push({ pathname: `/edit-questionnaire/${d.id}` });
				})
				.catch(console.error)
				.finally(() => setBlockedPanel(false));
		} else {
			// we update it
			setBlockedPanel(true);
			setPreviewEnabled(false);
			updateQuestionnaire(internalID, { nodes, metadata, vocabularies: vocs })
				.then(() => {
					toast.current.show({
						severity: 'success',
						summary: 'Questionnaire Updated',
						detail: '',
						life: 3000,
					});
					setTimeout(() => {
						setPreviewEnabled(true);
					}, 10000);
					setHasUnsavedWork(false);
				})
				.catch((_err) => {
					toast.current.show({
						severity: 'error',
						summary: 'Failed to update questionnaire',
						detail: '',
						life: 3000,
					});
				})
				.finally(() => setBlockedPanel(false));
		}
	};

	const acceptDownloadCarobScript = () => {
		setBlockedPanel(true);
		downloadCarobScript(internalID)
			.then((d) => {
				const name = data?.title?.toString().replaceAll(' ', '_') || 'Untitled';
				const element = document.createElement('a');
				element.setAttribute('download', `${name}.xlsx`);
				element.setAttribute('href', d.download_link);
				element.style.display = 'none';
				document.body.appendChild(element);
				element.click();
				document.body.removeChild(element);
			})
			.catch(console.error)
			.finally(() => setBlockedPanel(false));
	};

	const acceptDownload = () => {
		setBlockedPanel(true);
		downloadQuestionnaireAsXLSForm(internalID)
			.then((d) => {
				const name = data?.title?.toString().replaceAll(' ', '_') || 'Untitled';
				const element = document.createElement('a');
				element.setAttribute('download', `${name}.xlsx`);
				element.setAttribute('href', d.download_link);
				element.style.display = 'none';
				document.body.appendChild(element);
				element.click();
				document.body.removeChild(element);
				toast.current.show({
					severity: 'info',
					summary: 'XLSForm Created',
					detail: '',
					life: 3000,
				});
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => setBlockedPanel(false));
	};

	const openPreviewUrl = (url) => window.open(url, '_blank');

	const previewQuestionnaire = () => {
		setBlockedPanel(true);
		getPreviewQuestionnaireLink(internalID)
			.then((d) => {
				setLastPreviewUrl(d.url);
				openPreviewUrl(d.url);
			})
			.catch((_err) => {
				toast.current.show({
					severity: 'info',
					summary: 'Preview not ready',
					detail: 'Please "Update" the questionnaire first and try again.',
					life: 3000,
				});
				setPreviewEnabled(false);
			})
			.finally(() => setBlockedPanel(false));
	};

	const rightContentsMain = data ? (
		<>
			<ConfirmPopup
				target={document.getElementById('save_button')}
				visible={visibleSave}
				onHide={() => setVisibleSave(false)}
				message="Are you sure you want to proceed?"
				icon="fa-duotone fa-triangle-exclamation"
				accept={updateOrCreate}
			/>
			<Button
				id="preview_questionnaire"
				onClick={() => previewQuestionnaire()}
				icon="fa-duotone fa-magnifying-glass"
				label="Preview"
				disabled={!previewEnabled}
				loading={blockedPanel}
				className="p-button-secondary p-mr-2"
				tooltip={`Preview questionnaire. Make sure you "Update" your questionnaire fist.`}
				tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
			/>
			{/* <Button */}
			{/*	id="download_carob_script_button" */}
			{/*	onClick={() => acceptDownloadCarobScript()} */}
			{/*	icon="fa-regular fa-scroll" */}
			{/*	label="Export Carob Script" */}
			{/*	className="p-button-secondary p-mr-2" */}
			{/*	tooltip="Export Carob R script for questionnaire to standardize collected data" */}
			{/*	tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} */}
			{/* /> */}
			<Button
				id="download_button"
				onClick={() => acceptDownload()}
				icon="fa-duotone fa-download"
				label="Download"
				className="p-button-secondary p-mr-2"
				tooltip="Download Questionnaire in XLSForm"
				tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
			/>
			{!viewOnlyMode && (
				<Button
					id="save_button"
					onClick={() => setVisibleSave(true)}
					disabled={title?.length === 0}
					icon="fad fa-floppy-disk"
					label="Update"
					className="p-button-success"
					tooltip="Update your Questionnaire"
					tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
				/>
			)}
		</>
	) : (
		<>
			<ConfirmPopup
				target={document.getElementById('save_button')}
				visible={visibleSave}
				onHide={() => setVisibleSave(false)}
				message="Are you sure you want to proceed?"
				icon="fa-duotone fa-triangle-exclamation"
				accept={updateOrCreate}
			/>
			<Button
				id="save_button"
				onClick={() => setVisibleSave(true)}
				icon="fad fa-floppy-disk"
				label="Save"
				disabled={nodes?.length === 0 || title?.length === 0}
				className="p-button-success"
				tooltip="Save your Questionnaire"
				tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
			/>
		</>
	);

	const deleteNode = (node) => {
		const selectedNode = node.key;
		const filtered = nodes.filter((f) => f.key !== selectedNode);
		filtered.forEach((item) => deleteNodeFromTree(item, selectedNode));
		setNodes(filtered);
		findVocUsages(filtered);
	};

	const editNode = (node) => {
		if (node?.data?.id?.length === 0) {
			return;
		}

		setQuestion(questionsConfiguration[node.data.id]);
		setQuestionData({ ...node.data, key: node.key });
		setQuestionMode(QUESTION_MODE_UPDATE);
		setShowQuestion(true);
	};

	const setupBlockCreation = (node) => {
		setBlockedPanel(true);
		// find related vocs
		const relatedvocids = [];
		traverseTree([node], (n) => {
			if (n?.data?.id === 'selection') {
				relatedvocids.push(n?.data?.vocabulary);
			}
		});
		// populate the vocs needed for the block
		let blockVocabularies = [];
		if (relatedvocids.length) {
			blockVocabularies = [...vocs.filter((v) => relatedvocids.includes(v.id))];
		}
		setBlockData({
			vocabularies: blockVocabularies,
			nodes: [node],
		});
		setBlockedPanel(false);
		setCreateBlockModalVisible(true);
	};

	const actionTemplate = (node) => (
		<div>
			{!viewOnlyMode ? (
				<>
					<Button
						type="button"
						icon="fa-duotone fa-pen-to-square"
						className="p-button-success"
						style={{ marginRight: '.5em' }}
						onClick={() => editNode(node)}
					/>
					{node?.data?.id === 'group' && (
						<Button
							type="button"
							icon="fa-duotone fa-floppy-disk"
							tooltip="Save to my library"
							tooltipOptions={{ position: 'top' }}
							className="p-button-info"
							style={{ marginRight: '.5em' }}
							onClick={() => setupBlockCreation(node)}
						/>
					)}
				</>
			) : (
				<Button
					type="button"
					icon="fa-duotone fa-eye"
					className="p-button-info"
					style={{ marginRight: '.5em' }}
					onClick={() => editNode(node)}
				/>
			)}

			{!viewOnlyMode && (
				<Button
					type="button"
					disabled={node?.data?.is_editable === false}
					icon="fa-duotone fa-trash"
					className="p-button-danger p-mr-2"
					onClick={(e) => {
						confirmPopup({
							target: e.currentTarget,
							message: 'Are you sure you want to delete the question?',
							icon: 'pi pi-exclamation-triangle',
							accept: () => deleteNode(node),
						});
					}}
				/>
			)}
		</div>
	);

	const onDrag = (e) => {
		if (e.dragNode?.data?.is_editable === false) {
			// you can't drag non editable nodes
			return;
		}
		if (e.dropNode !== null) {
			// check if dropNode is of type Group
			if (e.dropNode?.data?.id !== 'group') {
				// do nothing
				return;
			}
		}
		setNodes(e.value);
	};

	const getNodeTemplateColor = (node) => {
		const errorColor = 'crimson';
		const successColor = '#689F38';

		const { id, semantics, no_semantic_term: noSemanticTerm, unit } = node.data;

		if (id === 'numeric' && !unit?.id) {
			return { color: errorColor };
		}

		// Questions with semantic terms.
		const needsSemanticTerms = ['numeric', 'selection', 'text', 'location'].includes(id);
		if (needsSemanticTerms) {
			if (semantics?.length > 0 || noSemanticTerm === true) {
				return { color: successColor, fontWeight: 600 };
			}

			if (semantics?.length === 0 && !noSemanticTerm) {
				return { color: errorColor };
			}
		}

		return {};
	};

	const nodeTemplate = (node) => {
		const classes = `${options.className} p-d-flex p-jc-between p-ai-center`;
		return (
			<div className={classes} style={{ flexGrow: 1 }}>
				<div style={getNodeTemplateColor(node)}>{node.label}</div>
				<div>{actionTemplate(node)}</div>
			</div>
		);
	};

	const renderTree = () => {
		return (
			nodes && (
				<div>
					<Toolbar left={leftContents} right={rightContents} />
					<div className="p-mt-3">
						{nodes?.length > 0 && (
							<Tree
								className="questions-tree"
								value={nodes}
								selectionMode="single"
								expandedKeys={expandedKeys}
								onSelectionChange={(e) => setSelectedNodeKey(e.value)}
								onSelect={onNodeSelect}
								onUnselect={onNodeUnselect}
								contextMenuSelectionKey={selectedNodeKey}
								onContextMenuSelectionChange={(e) => setSelectedNodeKey(e.value)}
								onContextMenu={(e) => cm.current.show(e.originalEvent)}
								onDragDrop={onDrag}
								dragdropScope="demo"
								onToggle={(e) => setExpandedKeys(e.value)}
								nodeTemplate={nodeTemplate}
								selectionKeys={selectedNodeKey}
							/>
						)}
					</div>
				</div>
			)
		);
	};

	const handleCreateVocabulary = useCallback(
		(voc) => {
			setVocs((oldVocs) => [...oldVocs, { ...voc, usedInQuestions: [] }]);
			setHasUnsavedWork(true);
		},
		[setVocs]
	);

	const handleUpdateVocabulary = useCallback(
		({ body }) => {
			const tempVocs = vocs.map((v) => (v.uuid === body.uuid ? body : v));
			setVocs([...tempVocs]);
			setHasUnsavedWork(true);
			findVocUsages(nodes);
		},
		[setVocs, vocs, findVocUsages, nodes]
	);

	const handleImportBlock = useCallback(
		({ vocabularies, nodes: blockNodes }) => {
			if (vocabularies?.length) {
				const tempVocs = [...vocs];
				const ids = tempVocs.map((v) => v.id);
				const uuids = tempVocs.map((v) => v.uuid);
				const filteredVocs = vocabularies.filter(
					(v) => !ids.includes(v.id) && !uuids.includes(v.uuid)
				);
				setVocs([...tempVocs, ...filteredVocs]);
			}

			if (blockNodes?.length) {
				const tempNodes = [...nodes];
				if (selectedGroup && selectedGroup?.node?.data?.id === 'group') {
					// we need to find the group and push to its children
					selectedGroup.node.children.push(...blockNodes);
				} else {
					setNodes([...tempNodes, ...blockNodes]);
				}
			}
		},
		[vocs, setVocs, nodes, selectedGroup]
	);

	useEffect(() => {
		// Temporarily because stated is changed on load and this means that we end up with
		// modified work even though no actual changes are made.
		setHasUnsavedWork(false);
	}, []);

	return (
		<div className="input-demo" style={{ paddingBottom: '56px' }}>
			<Prompt
				when={hasUnsavedWork}
				message="You have not saved your work, are you sure you want to leave?"
			/>
			<Toast ref={toast} />
			<ContextMenu model={menu} ref={cm} onHide={() => setSelectedNodeKey(null)} />
			<Toolbar right={rightContentsMain} className="p-mb-4" />
			<BlockUI blocked={blockedPanel}>
				<Panel
					header={<div className="p-ml-2">Questionnaire Settings</div>}
					toggleable
					headerTemplate={template}
					className="p-mb-4"
				>
					<QuestionnaireSettings
						title={title}
						viewOnlyMode={viewOnlyMode}
						setTitle={setTitle}
						languageValue={languageValue}
						setLanguageValue={setLanguageValue}
						languageValues={languageValues}
						version={version}
						setVersion={setVersion}
						updateOption={updateOption}
						options={options}
					/>
				</Panel>
			</BlockUI>
			<BlockUI blocked={blockedPanel}>
				<Panel
					header={<div className="p-ml-2">Questionnaire Choice Lists</div>}
					toggleable
					headerTemplate={template}
					className="p-mb-4"
				>
					<VocabulariesTable
						viewOnlyMode={viewOnlyMode}
						vocToLoad={vocToLoad}
						setVocToLoad={setVocToLoad}
						vocs={vocs}
						setVocs={setVocs}
						vocOptions={vocOptions}
						setBlockedPanel={setBlockedPanel}
						handleCreateVocabulary={handleCreateVocabulary}
						setHasUnsavedWork={setHasUnsavedWork}
						setSelectedVocabulary={setSelectedVocabulary}
						setVocModalVisible={setVocModalVisible}
					/>
				</Panel>
			</BlockUI>
			<BlockUI blocked={blockedPanel}>
				<Panel
					header={<div className="p-ml-2">Questions</div>}
					toggleable
					headerTemplate={template}
				>
					{renderTree()}
				</Panel>
			</BlockUI>
			<div>
				<Question
					configuration={question}
					show={showQuestion}
					output={printOutput}
					data={questionData}
					mode={questionMode}
					ontologies={ontologies}
					vocabularies={vocs}
					viewOnlyMode={viewOnlyMode}
					languages={languageValues}
					language={languageValue}
				/>
				<VocabularyModal
					viewOnlyMode={viewOnlyMode}
					languages={languageValues}
					onlyDelete
					internalId={selectedVocabulary?.id}
					vocabulary={selectedVocabulary?.body || selectedVocabulary}
					onCreate={handleCreateVocabulary}
					onUpdate={handleUpdateVocabulary}
					visible={vocModalVisible}
					setVisible={setVocModalVisible}
				/>
				<CreateBlockModal
					toast={toast}
					visible={createBlockModalVisible}
					setVisible={setCreateBlockModalVisible}
					blockData={blockData}
					setBlockData={setBlockData}
				/>
				<ImportBlockModal
					toast={toast}
					visible={importBlockModalVisible}
					setVisible={setImportBlockModalVisible}
					libraryBlocks={libraryBlocks}
					onImportBlock={handleImportBlock}
				/>
				<PreviewQuestionnaireDialog
					url={lastPreviewUrl}
					dialogOpen={previewDialogOpen}
					setDialogOpen={setPreviewDialogOpen}
				/>
			</div>
		</div>
	);
};

export default QuestionnaireForm;
