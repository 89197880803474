import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { confirmPopup } from 'primereact/confirmpopup';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { useState, useCallback } from 'react';
import { getVocabulary } from '../../../services/vocabularies';

const VocabulariesTable = ({
	viewOnlyMode,
	vocToLoad,
	setVocToLoad,
	vocs,
	setVocs,
	vocOptions,
	setBlockedPanel,
	handleCreateVocabulary,
	setHasUnsavedWork,
	setSelectedVocabulary,
	setVocModalVisible,
}) => {
	const [tableSearch, setTableSearch] = useState('');

	const vocNameTemplate = (rowData) => (
		<div>
			{rowData?.body?.listname || rowData?.listname || 'N/A'}
		</div>
	);

	const handleLoadVocabulary = useCallback(async () => {
		if (vocToLoad === null) return;
		setBlockedPanel(true);
		try {
			const { data: voc } = await getVocabulary(vocToLoad.id);
			handleCreateVocabulary(voc.body);
		} catch (e) {
			console.error(e);
		} finally {
			setBlockedPanel(false);
			setVocToLoad(null);
		}
	}, [vocToLoad, handleCreateVocabulary, setBlockedPanel, setVocToLoad]);

	const handleDeleteVocabulary = useCallback(
		(uuid) => {
			const tempVocs = vocs.filter((v) => v.uuid !== uuid);
			setVocs([...tempVocs]);
			setHasUnsavedWork(true);
		},
		[setVocs, vocs, setHasUnsavedWork]
	);

	const vocsActionsTemplate = (rowData) => (
		<div className="p-text-right">
			<Button
				icon="fa-duotone fa-pen-to-square"
				className="p-button-success"
				tooltip="Edit"
				tooltipOptions={{ position: 'top' }}
				onClick={() => {
					setSelectedVocabulary(rowData);
					setVocModalVisible(true);
				}}
			/>
			{!viewOnlyMode && rowData.usedInQuestions?.length === 0 && (
				<Button
					icon="fa-duotone fa-trash"
					tooltip="Delete"
					tooltipOptions={{ position: 'top' }}
					className="p-button-danger p-ml-2"
					onClick={(e) => {
						confirmPopup({
							target: e.currentTarget,
							message: 'Are you sure you want to delete this choice list?',
							icon: 'pi pi-exclamation-triangle',
							accept: () => handleDeleteVocabulary(rowData.uuid),
						});
					}}
				/>
			)}
		</div>
	);

	const tableHeader = () => (
		<div className="p-d-flex p-jc-end">
			<span className="p-input-icon-right">
				<i className="pi pi-search" />
				<InputText
					value={tableSearch}
					onChange={(e) => setTableSearch(e.target.value)}
					placeholder="Filter"
				/>
			</span>
		</div>
	);

	return (
		<div className="p-text-right">
			<div className="p-mb-3">
				{!viewOnlyMode && (
					<>
						<Dropdown
							optionLabel="listname"
							filter
							disabled={viewOnlyMode}
							filterBy="listname"
							value={vocToLoad}
							onChange={(e) => setVocToLoad(e.value)}
							optionDisabled={(opt) => vocs.filter((v) => v.uuid === opt.external_id).length > 0}
							options={vocOptions}
							placeholder="Select from your choice lists"
							className="multiselect-custom p-mr-2"
						/>
						<Button
							icon="fa-duotone fa-download"
							className="p-button-info"
							label="Load"
							disabled={vocToLoad === null}
							onClick={handleLoadVocabulary}
						/>
					</>
				)}
			</div>
			<DataTable
				paginator
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport"
				currentPageReportTemplate="Total records: {totalRecords}"
				header={tableHeader}
				globalFilter={tableSearch}
				globalFilterFields={['listname', 'description']}
				rows={10}
				rowsPerPageOptions={[10, 20, 50]}
				rowHover
				emptyMessage="No choice lists have been loaded."
				value={vocs}
			>
				<Column header="Name" body={vocNameTemplate} />
				<Column
					header="Description"
					field="description"
					bodyStyle={{ maxWidth: '1000px' }}
					body={(item) => item?.body?.description || item?.description || ''}
				/>
				<Column
					header="Used In Questions"
					alignHeader="center"
					bodyClassName="p-text-center"
					body={({ usedInQuestions }) => usedInQuestions?.length || 0}
				/>
				<Column header="Actions" alignHeader="right" body={vocsActionsTemplate} />
			</DataTable>
		</div>
	);
};

export default VocabulariesTable;
